<template>
  <v-app v-if="evento">
	    <v-container>
    <v-layout row wrap>
      <!--<v-flex lg12 md12 sm12 xs12>
        <center class="area_mapeadaAuditorio">
          <img 
          :src="evento.auditorio_mapeado.imagen"
          usemap="#map">
        </center>
      </v-flex>-->
      <v-flex lg12 md12 sm12 xs12>
        <div class="sub" id:="boletas">
          <BoletasxLocalidad @informacion-enviada="recibirInformacion" :pagos="true" :fisico="true" v-if="idLocalidad!=''" :idlocalidad="idLocalidad" :idEvento="evento.id" :monedaEvento="monedaSelectShopEvento" :indexMonedaSelectShopEvento="indexMonedaSelectShopEvento" :simboloMoneda="simboloMoneda" :monedasEvento="evento.monedas_evento"></BoletasxLocalidad>
        </div>
      </v-flex>
      <v-flex lg12 md12 sm12 xs12>
      	<div class="mb-3 mt-3">
      		<h2>Venta de boletas</h2>
      	</div>
        <div>
          <span><router-link style="color: #1969ed !important" :to="{path: '/mis-eventos'}" title="Ver todos mis eventos"><u>Seleccionar otro evento</u></router-link></span><span> > </span><span>Nueva venta de boletas</span>
        </div>
        <div class="mt-3 mb-4">
        <h2 class="bg-white p-3">Evento <b v-text="evento ? evento.nombre : 'Cargando...'"></b></h2>
    </div>
      </v-flex>
    </v-layout>
    <div v-if="evento.fechaValidaInicioPuntosVenta == 1">
    <template>
      <v-layout row wrap v-if="preventasEvento.length <= 0">
        <v-flex lg6 md6 sm12 xs12 mb-3>
        <v-alert
      dense
      :value="true"
      border="left"
      type="warning">
<b>No hay preventas disponibles en este momento...</b>
  </v-alert>
</v-flex>
    </v-layout>
      <div v-else>
        <v-flex>
          <v-chip @click="verPreventas">
            <v-icon left color="#907b16">remove_red_eye</v-icon>
            <b v-if="!mostrarPreventas">VER PREVENTA</b>
            <b v-else>OCULTAR PREVENTA</b>
          </v-chip>
        </v-flex>
      <div class="my-5">
        <v-data-table
          :headers="headersPreventa"
          :items="preventasEvento"
          item-key="id"
          class="elevation-1 center"
          v-show="mostrarPreventas"
          hide-default-footer
         :itemsPerPage="preventasEvento.length"
        >
          <template v-slot:item.tipo="preventa">
            <td>
              <div class="d-flex pt-2">
                <p class="mb-1 pr-3">{{ preventa.item.tipo == 1 ? 'Todo el evento' : ( preventa.item.tipo == 2 ? 'Solo tribunas' : 'Solo localidades') }}</p>
                <button :title="'Ver ' + (preventa.item.tipo == 2 ? 'tribunas' : 'localidades')" data-toggle="modal" :data-target="'#modal-tabla-preventa' + preventa.item.id" type="button" class="btn btn-sm p-1 mb-2 mt-0 btn-outline-secondary icon-preview-data" v-if="preventa.item.tipo == 2 || preventa.item.tipo == 3">
                  <v-icon center>remove_red_eye</v-icon>
                </button>
                <div class="modal fade" :id="'modal-tabla-preventa' + preventa.item.id" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog d-flex align-items-center h-100">
                    <div class="modal-content p-4">
                      <div class="mb-3">
                        <h3 style="font-weight: bold">{{preventa.item.nombre}}</h3>
                      </div>
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">ID DE {{preventa.item.tipo == 2 ? 'TRIBUNA' : 'LOCALIDAD'}}</th>
                            <th scope="col">{{preventa.item.tipo == 2 ? 'NOMBRE TRIBUNA' : 'NOMBRE LOCALIDAD'}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr :key="index2 + preventa.item.nombre" v-for="(item, index2) in preventa.item.info_tipo">
                            <th scope="row">{{item.id}}</th>
                            <td>{{item.nombre}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </template>
          <template v-slot:item.tipo_descuento_precio="preventa">
            <td>{{ preventa.item.tipo_descuento_precio == 1 ? 'Porcentaje' : 'Valor fijo' }}</td>
          </template>
          <template v-slot:item.tipo_descuento_servicio="preventa">
            <td>{{ preventa.item.tipo_descuento_servicio == 1 ? 'Porcentaje' : 'Valor fijo' }}</td>
          </template>
          <template v-slot:item.automatica="preventa">
            <td>{{ preventa.item.automatica == 0 ? 'NO' : 'SI' }}</td>
          </template>
          <template v-slot:item.actions="preventa">
            <td class="d-flex">
              <v-checkbox
                :disabled="totalValorBoletasCarrito > 0"
                v-model="arrayPreventasLog"
                :label="arrayPreventasSelect.includes(preventa.item.id) ? 'Activada' : 'Desactivada'"
                color="blue"
                @change="uncheckOtherOptions(preventa.item.id)"
                v-if="preventa.item.automatica == 0"
                :value="preventa.item.id"
              ></v-checkbox>
            </td>
          </template>
        </v-data-table>
      </div>
    </div>
    </template>
    <v-layout row v-if="evento.id_tipo_evento==7" >
        

     
        <div class="  col-6"  >
     <v-select 
          v-model="seleccionado" 
          :items="$store.state.fechafunciones" 
          label="Seleccion Una Fecha Para ver las Funciones "
          prepend-icon="payment" 
          success
          persistent-hint
          @change="cargarhorario"></v-select>
      
          <table class="table" style=" width: 100%;"  >
                                <thead>
                                  <tr>
                                    <th>Fecha</th>
                                    <th>Hora</th>
                                    <th>Aforo</th>
                                    <th> </th>
                                  </tr>
                                </thead>
                                <tbody v-if="seleccionado !== null">
                                  <tr v-for="item in $store.state.funcioneshora" :key="item.id">
                                    <td>{{ item.fechainicio }}</td>
                                    <td>{{ item.horainicio }}</td>
                                    <td>{{ item.aforo }}</td>
                                    <td>   <button class="btn btn-success"
                                             :disabled="$store.state.carrito.cantBoletaTotal !== 0 && funcionseleccionada!==item.id"
                                             @click="cargarPreciosFunciones( item.id)"> Ver</button>   </td>
                                  </tr>
                                </tbody>
                              </table>
        
                              <v-alert v-if="funcionseleccionada>0"
                                    dense
                                    :value="true" 
                                    type="success">
                                    <p> 
                                      &nbsp; FECHA: {{ $store.state.funcioneshora2[funcionseleccionada]['fecha'] }}&nbsp;&nbsp;
                                      &nbsp; HORA:  {{ $store.state.funcioneshora2[funcionseleccionada]['hora'] }}&nbsp;&nbsp;
                                      &nbsp;AFORO: {{ $store.state.funcioneshora2[funcionseleccionada]['aforo'] }}&nbsp;&nbsp;
                                    
                                    </p>
                                </v-alert>
        
                  
            </div>
 

          
      
         


    </v-layout>
    <v-layout row wrap  >
      <v-flex mb-5 lg8 md8 sm6 xs12 >
   
   <div     v-if="evento.id_tipo_evento !== 7 || seleccionado !== null" >
   
        <v-data-table 
      :headers="headers"
      :items="listLocalidadesEventoAuditorio_mapeado"
      hide-default-footer
      :itemsPerPage="listLocalidadesEventoAuditorio_mapeado.length"
      item-key="id"
      class="elevation-1"
      style="max-height: 600px; overflow-y: scroll"
      :key="arrayPreventasSelect.length"
    >
      <template v-slot:item.nombreTribuna="{item}">
        <td @click="getPuestos(item.id,null)" class="font-weight-bold"><h5 class="f-16">{{ item.tribuna.nombre }}</h5></td>
      </template>
      
      <template v-slot:item.nombre="{item}">
        <td  @click="getPuestos(item.id,null)" class="font-weight-bold"><h5 class="f-16">{{ item.nombre }}</h5></td>
      </template>
      <template v-slot:item.precio="{item}">
        <td  @click="getPuestos(item.id,null)" class="font-weight-bold"><h5 class="f-16">{{ simboloMoneda }} {{ getLocalidadDescuento(item, false) }}</h5></td>
      </template>
      <template v-slot:item.precioDescuento="{item}">
        <td  @click="getPuestos(item.id,null)" class="font-weight-bold"><h5 class="f-16">{{ simboloMoneda }} {{ getLocalidadDescuento(item, true) }}</h5></td>
      </template>
    </v-data-table>

  </div>

</v-flex>
  <v-flex mb-5 lg4 md4 sm6 xs12 p-5> 
    <v-flex align-self-end>
  <v-chip @click="mapasientos(true)">
    <v-icon left color="#907b16">search</v-icon>
    <b>VER MAPA DE PUESTOS</b>
  </v-chip>  
  <br>
</v-flex>
    <v-select v-if="showSelect"
          v-model="monedaSelectShop"
          item-value="codigo_moneda"
          :item-text="concatTextMoneda"
          :items="evento.monedas_evento"
          label="Cambiar moneda de compra"
          prepend-icon="payment"
          @change="changeMonedaShopEvento"
          success
          persistent-hint
        ></v-select>
  <!-- <h3>Boletas: {{ countBoletas }}</h3> <h3>Palcos: {{ countPalcos }}</h3> <h3>Total: {{ simboloMoneda }} {{ formatNumber(totalValorBoletasCarrito) }} <small class="text-success">({{monedaSelectShopEvento}})</small></h3>  -->
    <v-btn @click="verResumenCompra()" color="info"><v-icon>shopping_cart</v-icon>&nbsp; VER RESUMEN CARRITO</v-btn>
    <br><br>
    <v-chip @click="clearCantBoleta">
      <v-icon center color="#907b16">remove_shopping_cart</v-icon>
      <b>&nbsp;VACIAR CARRITO</b>
    </v-chip>
  </v-flex>
</v-layout>
<form v-if="dateTimeSaleStart != null && dateTimeSaleEnd != null" action="#" @submit.prevent="newSale">  
<h2><v-icon center>shopping_cart</v-icon> Continuar con la venta:</h2>
<!-- <v-divider></v-divider>
<v-checkbox
  v-model="pagoParcial"
  class="check_asignar_pv"
  label="Marcar venta como pago parcial"
  @click="changeFormaPagoParcial"
></v-checkbox> -->
<v-divider></v-divider>
<h4 class="mb-3 text-secondary"><v-icon center>person</v-icon> Datos del comprador</h4>
<v-layout row wrap pl-4 v-if="evento.id_tipo_evento == 8">
 
 <Participantes :informacion="informacionParticipantes" v-if="evento.id_tipo_evento == 8"></Participantes>
 <br>

</v-layout>
<v-layout row wrap pl-4>
   <v-flex xs12 sm4 lg4 md4 pr-2 mb-3>
       <v-select v-if="showSelect"
          v-model="userSale.tipo_identificacion"
          item-value="id"
          item-text="code"
          :items="tipoIdentificaciones"
          label="Tipo de identificación (*)"
          prepend-icon="account_box"
          persistent-hint
          :hint="descripcionTipoIdentificacion"
          required
          :readonly="busquedaUser"
        ></v-select>
   </v-flex>
    <v-flex xs12 sm4 lg4 md4 pr-2 mb-3> 
       <v-text-field
            prepend-icon="account_box"
            label="Identificacion usuario"
            v-model="userSale.identificacion"
            @keydown.tab="searchUser"
            :readonly="busquedaUser"
            required
          ></v-text-field>
    </v-flex>
     <v-flex xs12 sm4 lg4 md4 mb-3>
      <v-btn color="primary" @click="searchUser" v-if="!busquedaUser"><v-icon>search</v-icon> BUSCAR USUARIO</v-btn>
      <h6 v-if="!busquedaUser"><b>OBLICATORIO CLICk EN BUSCAR USUARIO</b></h6>
      <v-btn color="error" @click="limpiarUser(false)" v-if="busquedaUser"><v-icon>delete</v-icon> LIMPIAR BUSQUEDA</v-btn>
     </v-flex>
</v-layout>
<v-layout row wrap pl-4 v-if="show_datos">
<v-flex xs12 sm4 lg3 md4 pr-2 mb-3>
      <v-text-field
        prepend-icon="person"
        label="Nombres"
        v-model="userSale.nombre"
        required
      ></v-text-field>
</v-flex>
<v-flex xs12 sm4 lg3 md4 pr-2 mb-3>
      <v-text-field
        prepend-icon="person"
        label="Apellidos"
        v-model="userSale.apellidos"
        required
      ></v-text-field>
</v-flex>
<v-flex xs12 sm4 lg3 md4 pr-2 mb-3>
      <v-text-field
        prepend-icon="alternate_email"
        label="Correo electronico"
        v-model="userSale.email"
        :readonly="tabActive"
        type="email"
        required
      ></v-text-field>
</v-flex>
<v-flex xs12 sm4 lg3 md2 pr-2 mb-3>
      <v-text-field
        prepend-icon="call"
        label="Teléfono"
        v-model="userSale.telefono"
        type="number"
        min="0"
        minlength="10"
        @blur="validarTelefono"
        required
      ></v-text-field>
</v-flex>
</v-layout>
<v-layout row wrap pl-4 v-if="show_datos">
<v-flex xs12 sm4 lg4 md4 pr-2 mb-4>
    <v-select
      v-model="userSale.departamento"
      item-value="id"
      item-text="descripcion"
      :items="departamentos"
      label="Departamento de residencia"
      prepend-icon="location_on"
      persistent-hint
      required
    ></v-select>
</v-flex>
<v-flex xs12 sm4 lg4 md4 pr-2 mb-3>
      <v-select
      v-model="userSale.ciudad"
      item-value="id"
      item-text="descripcion"
      :items="ciudadesSelect"
      label="Ciudad de residencia"
      prepend-icon="location_on"
      persistent-hint
      required
      :disabled="userSale.departamento == null"
      no-data-text="Selecciona un departamento"
    ></v-select>
</v-flex>
<v-flex xs12 sm4 lg4 md4 pr-2 mb-3>
      <v-text-field
        prepend-icon="home"
        label="Direccion de residencia"
        v-model="userSale.direccion"
      ></v-text-field>
</v-flex>
</v-layout>
<h4 class="mb-3 text-secondary"><v-icon center>payment</v-icon> Forma de pago ({{ monedaSelectShopEvento }})</h4>
<v-layout row wrap pl-4>
  <v-flex xs12 sm6 lg6 md6 mb-3>  
  		<v-select v-if="showSelect"
  		  :items="formasPago"
  		  name="formaPago"
  		  :label="'Seleccione un metodo de pago ('+monedaSelectShopEvento+')'"
  		  v-model="formaPago"
  		  item-text="text"
        prepend-icon="payment"
        @change="changeFormaPago"
        required
  		  ></v-select>
  </v-flex>
  <v-flex xs12 sm6 lg6 md6 mb-3 v-if="formaPago != 'efectivo' && formaPago != 'empresario'">
   <v-select v-if="showSelect"
        :items="formasPagoTarjeta"
        name="formaPagoTarjeta"
        label="Seleccione tipo de tarjeta"
        v-model="formaPagoTarjeta"
        item-text="text"
        prepend-icon="payment"
        required
        ></v-select>
  </v-flex>
</v-layout>
<v-layout row wrap pl-4 v-if="formaPago == 'mixto'">
  <v-flex xs12 sm6 lg6 md6 mb-3>
      <v-text-field
      type="number"
      prepend-icon="payment"
        :label="'Monto con tarjeta ('+monedaSelectShopEvento+')'"
        :prefix="simboloMoneda"
        filled
        v-model="montoTarjeta"
        @keyup="validateSumaPagoMixtotarjeta"
        required
        ></v-text-field>
  </v-flex>
  <v-flex xs12 sm6 lg6 md6 mb-3>
         <v-text-field
         type="number"
         prepend-icon="attach_money"
        :label="'Monto en efectivo ('+monedaSelectShopEvento+')'"
        :prefix="simboloMoneda"
        filled
        v-model="montoEfectivo"
        @keyup="validateSumaPagoMixto"
        required
        ></v-text-field>
  </v-flex>
</v-layout>
<v-layout row wrap>
  <v-flex xs12 sm12 lg12 md12 mb-3 v-if="formaPago != 'efectivo' && formaPago != 'empresario'" pl-4>
    <v-text-field
        prepend-icon="payment"
        label="Codigo del voucher"
        v-model="codeVoucher"
        required
      ></v-text-field>
  </v-flex>
</v-layout>
<v-layout row wrap v-if="pagoParcial && formaPago != 'mixto'">
<v-flex lg12 md12 sm12 xs12 mb-5>
        <v-text-field
        type="number"
        prepend-icon="payment"
        :label="formaPago == 'efectivo' ? 'Monto en efectivo para el pago parcial' : 'Monto con tarjeta para el pago parcial'"
        :prefix="simboloMoneda"
        filled
        required
        v-model="montoPagoParcial"
        @keydown="validateMontoPagoParcial"
        ></v-text-field>
        <small>Este valor no debe superar ni ser igual al total de la venta.</small>
    </v-flex>
  </v-layout>
<h4 class="mb-3 text-secondary"><v-icon center>theaters</v-icon> Entrega de boletas</h4>
<v-layout row wrap>
<v-flex lg12 md12 xm12 xs12 mb-3>
     <v-select v-if="showSelect"
        :items="evento.tipo_entrega_boletas"
        name="entregaBoletas"
        label="Seleccione el medio de entrega de las boletas"
        v-model="entregaBoletas"
        item-text="text"
        prepend-icon="theaters"
        required
      ></v-select>
</v-flex>
</v-layout>
<v-divider></v-divider>
<v-layout row wrap>
<v-flex lg12 md12 xm12 xs12 mb-3>
  <v-textarea
    name="observacion"    
    label="Observación"
    auto-grow
    v-model="observacion"
    ></v-textarea>
</v-flex>
</v-layout>
<v-divider></v-divider>
<div class="text-right">
    <!-- <h3>Boletas: <b>{{ countBoletas }}</b></h3> <h3>Palcos: <b>{{ countPalcos }}</b></h3> <h3>Total a pagar: <b>{{ simboloMoneda }} {{ formatNumber(totalValorBoletasCarrito) }}</b> <small class="text-success"> ({{monedaSelectShopEvento}})</small></h3>  -->    
    <!-- <v-layout row wrap>
      <v-flex lg8 md8 sm8 xs12>
      </v-flex>
    <v-flex lg4 md4 sm4 xs12 mb-3>
        <v-text-field
        type="number"
      prepend-icon="payment"
        label="Efectivo recibido"
        :prefix="simboloMoneda"
        filled
        v-model="efectivoRecibido"
        ></v-text-field>
    </v-flex>
    <v-flex lg8 md8 sm8 xs12>
      </v-flex>
    <v-flex lg4 md4 sm4 xs12 mb-3>
       <v-text-field
       type="number"
       readonly
        prepend-icon="payment"
        label="Vueltos para entregar"
        :value="((montoEfectivo > 0 && efectivoRecibido > 0 && efectivoRecibido > montoEfectivo) ? formatNumber(efectivoRecibido - montoEfectivo) : (formaPago == 'efectivo' && efectivoRecibido > 0 && efectivoRecibido > totalValorBoletasCarrito) ? simboloMoneda+formatNumber(efectivoRecibido - totalValorBoletasCarrito) : 0)"
      ></v-text-field>
    </v-flex>
  </v-layout> -->
</div>

<div class="text-right">
  <!-- <v-btn type="submit" color="success"><v-icon>shopping_cart</v-icon>&nbsp; FINALIZAR VENTA</v-btn> -->
  <v-btn @click="verResumenCompra()" color="info"><v-icon>shopping_cart</v-icon>&nbsp; VER RESUMEN CARRITO</v-btn>
</div>
<v-divider></v-divider>
</form>
</div>
    <div v-else>
      <v-alert color="red" :value="true">
        <v-icon left color="white">remove_shopping_cart</v-icon> <b class="text-uppercase">Solo se pueden realizar ventas a partir del {{ formatDatetimeString(`${formatFecha(evento.fecha_inicio_venta_puntos)} ${evento.hora_inicio_venta_puntos}`) }}.</b>
      </v-alert>
    </div>
 <v-dialog v-model="dialogmapasientos" v-if="activeModalAsientos">
        <v-card>
          <v-card-title class="headline">
            <v-alert
              :value="true"
              color="success"
            >
              Selecciona tu localidad
            </v-alert>
          </v-card-title>    

          <v-card-text>
            <center class="area_mapeadaAuditorio2">
              <img 
              :src="evento.auditorio_mapeado.imagen"
              usemap="#map">
            </center>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="green darken-1"
              flat="flat"
              @click="mapasientos(false)"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      </v-container>
      <v-dialog v-model="$store.state.showModalFinalizarCompra" max-width="700">
      <div class="container-modal-resumen" v-if="$store.state.resumenFinalizarCompra">
        <h2 class="text-center mb-5">Resumen de Venta</h2>
        <table width="100%" border="0" cellspacing="0" cellpadding="0" class="table">
          <tr  :key="index" v-for="(product, index) in $store.state.resumenFinalizarCompra.productos">
            <td style="text-align:left; font-weight: bold;">&nbsp;{{product.nombre}}</td>
            <td  style="text-align:right; font-weight: bold;">&nbsp;{{product.total}}</td>
          </tr>
          <tr  >
            <td style="text-align:left; font-weight: bold;">&nbsp; </td>
            <td  style="text-align:right; font-weight: bold;">&nbsp; </td>
          </tr>
          <tr>
            <td style="text-align:center; font-weight: bold; background:#F3F3F3;">&nbsp; TOTAL:</td>
            <td  style="text-align:right; font-weight: bold; background:#F3F3F3;">&nbsp; {{$store.state.resumenFinalizarCompra.totalVenta   }}</td>
          </tr>

        </table>
         
        <!-- <div class="text-center mb-5 pb-5">
          <h5 class="text-center mb-5">ELIGE UN MÉTODO PARA REALIZAR TU PAGO</h5>
          

          <v-select
                  v-model="selectedmetodo" 
                  :items="metodospago"
                  label="Cambiar método de pago"
                  prepend-icon="payment"  
                  success
                  persistent-hint
                ></v-select>
                <p>Has seleccionado: {{ selectedmetodo }}</p>
        </div> -->
        <div class="text-center mb-5 pb-5">
          <v-alert
          v-if="!tabactiveboton"
            color="warning"
            type="warning"
            prominent
            title="Datos Incompletos" 
            text-color="white"
          >Para finalizar la venta debe completar toda la información de la venta</v-alert>
          <v-btn color="success" style="min-width: 250px" class="btn-finalizar-compra" v-if="!tabactiveboton" disabled>
            <v-icon center>shopping_cart</v-icon>&nbsp;FINALIZAR VENTA
          </v-btn>
          <v-btn color="success" style="min-width: 250px" class="btn-finalizar-compra" v-if="evento.status==1 && tabactiveboton" @click="newSale()">
            <v-icon center>shopping_cart</v-icon>&nbsp;FINALIZAR VENTA
          </v-btn>
        </div>
        <!-- <div class="d-flex flex-wrap">
          <img src="https://static.placetopay.com/placetopay-logo.svg" class="col-12 col-md-6 p-0 mb-4 mb-md-0" alt="Logo placetopay" style="max-height:150px;">
          <img src="@/assets/images/mediosPago.jpeg" class="col-12 col-md-6 p-0" alt="medios de pago placetopay">
        </div> -->
      </div>
    </v-dialog>
  </v-app>
</template>
<script>
import BoletasxLocalidad from "./utils/BoletasxLocalidad.vue";
import Participantes from "./utils/Participantes.vue";
import moment from "moment";

moment.locale("es");

export default {
  components: {
    BoletasxLocalidad,
    Participantes
  },
  data() {
    return {
      informacionParticipantes: '',
      tabActive: null,
      tabactiveboton : false,
      opcionesfechas: [ ],
      seleccionado: null,
      seleccionadohora:'',
      funcionseleccionada: 0,
      arrayPreventasLog: [],
      codeVoucher: null,
      observacion: '',
      formaPago: "efectivo",
      montoTarjeta: 0,
      montoEfectivo: 0,
      formaPagoTarjeta: "debito",
      formasPago: [
        {
          text: "Efectivo",
          value: "efectivo",
        },
        {
          text: "Tarjeta",
          value: "tarjeta",
        },
        {
          text: "Mixto",
          value: "mixto",
        },
        {
          text: "Empresario",
          value: "empresario",
        },
      ],
      formasPagoTarjeta: [
        {
          text: "Credito",
          value: "credito",
        },
        {
          text: "Debito",
          value: "debito",
        },
      ],
      idLocalidad: "",
      mostrarPreventas: false,
      monedaSelectShop: null,
      dialogmapasientos: false,
      efectivoRecibido: 0,
      entregaBoletas: "",
      showSelect: false,
      pagoParcial: false,
      montoPagoParcial: 0,
      activeModalAsientos: false,
      show_datos : false
    };
  },
  watch: {
    arrayPreventasLog(){
      this.$store.commit(
        "carrito/arrayPreventasSelect",
        this.arrayPreventasLog
      );
    },
    dialogmapasientos() {
      setTimeout(() => {
        var self = this;
        $(".area_mapeadaAuditorio2").append(
          $("<textarea />")
            .html(this.evento.auditorio_mapeado.area_mapeada_admin)
            .text()
            .replaceAll("&nbsp;", "")
        );
        $(".maparea").click(function (e) {
          e.preventDefault();
          var texthref = e.currentTarget.href;
          var arrayhref = texthref.split("/");
          self.getPuestos(arrayhref[arrayhref.length - 1], null);
          window.location.hash = "boletas";
          //$("html, body").animate({ scrollTop: $('#boletas').offset().top }, 1);
          self.dialogmapasientos = false;
          //$("html, body").animate({ scrollTop: 700 }, 1000);
        });
      }, 200);
    },
  },
  async mounted() {
    this.listLocalidadesEventoAuditorio_mapeado=null;
    this.arrayPreventasLog = this.$store.getters["carrito/arrayPreventasSelect"];
    var self = this;
    setTimeout(() => {
      self.showSelect = true;
    }, 400);

    self.$store.state.carrito.activarEmailForm = null

    await self.$store.dispatch("evento/getEvento", this.$route.params.evento);

    if (this.monedaSelectShopEvento === null) {
      this.$store.commit(
        "evento/changeMonedaShopEvento",
        this.evento.monedas_evento[0].codigo_moneda
      );
      this.monedaSelectShop = this.monedaSelectShopEvento;
    } else {
      this.monedaSelectShop = this.monedaSelectShopEvento;
    }

    await this.$store.dispatch("evento/changeMonedaShopEvento", {
      codigo_moneda: this.monedaSelectShopEvento,
      evento_id: this.$route.params.evento,
    });

    if (isNaN(this.userSale.ciudad)) {
      this.userSale.ciudad = null;
    } else {
      this.userSale.ciudad = parseInt(this.userSale.ciudad);
    }
    if (isNaN(this.userSale.departamento)) {
      this.userSale.departamento = null;
    } else {
      this.userSale.departamento = parseInt(this.userSale.departamento);
    }

    window.setInterval(() => {
      this.validateDateTimeSale();
    }, 1000);

    var self = this;
    setTimeout(() => {
      self.activeModalAsientos = true;
    }, 500);
    setTimeout(() => {
      $(".area_mapeadaAuditorio2").append(
        $("<textarea />")
          .html(
            self.$store.getters["evento/evento"].auditorio_mapeado.area_mapeada_admin
          )
          .text()
          .replace("&nbsp;", "")
      );
      $(".maparea").click(function (e) {
        e.preventDefault();
        var texthref = e.currentTarget.href;
        var arrayhref = texthref.split("/");
        self.getPuestos(arrayhref[arrayhref.length - 1], null);
        window.location.hash = "boletas";
        //$("html, body").animate({ scrollTop: $('#boletas').offset().top }, 1);
        self.dialogmapasientos = false;
        $("html, body").animate({ scrollTop: 0 }, 1000);
      });
    }, 900);
     
if(this.evento.id_tipo_evento==7){
    this.traerFechasFunciones();
}


  },
  async beforeCreate() {
    document.title = "Venta de boletas - TicketShop";
    await this.$store.dispatch("evento/getEvento", this.$route.params.evento);
    this.$store.dispatch("departamento/listDepartamentos");
    this.$store.dispatch("user/listTipoIdentificaciones");
    this.$store.dispatch("ciudad/listCiudades");
    if (this.evento.status != 1) {
      this.$router.push({ name: "misEventos" });
    }
    var autorizadoPuntoVenta = this.evento.puntoventa_eventos.filter(
      (item) => item.punto_ventum  ? (item.punto_ventum.email == this.usuario.email) : false
    );
    if (autorizadoPuntoVenta.length <= 0) {
      this.$router.push({ name: "misEventos" });
    }
    this.$store.dispatch(
      "auditorio_mapeado/listLocalidadesEventoAuditorio_mapeado",
      this.$route.params.evento
    );
    
    this.$store.dispatch(
      "auditorio_mapeado/listLocalidadesEventoAuditorioGeneral_mapeado",
      this.$route.params.evento
    );

    this.$store.dispatch(
      "evento/getPreventas",
      this.$route.params.evento
    );
    this.$store.dispatch("moneda/listMonedas");
   
     
  },

  computed: {
    arrayPreventasSelect(){
      return this.$store.getters["carrito/arrayPreventasSelect"];
    },
    usuario() {
      return this.$store.getters["user/dataUserLogged"];
    },
    evento() {
      return this.$store.getters["evento/evento"];
    },
    monedas() {
      return this.$store.getters["moneda/listMonedas"];
    },
    cantBoleta() {
      return this.$store.getters["evento/cantBoleta"];
    },
    cantBoletaTotal() {
      return this.$store.getters["evento/cantBoletaTotal"];
    },
    acumuladoBoleta() {
      return this.$store.getters["evento/acumuladoBoleta"];
    },
    listLocalidadesEventoAuditorio_mapeado() {
      return this.$store.getters[
        "auditorio_mapeado/listLocalidadesEventoAuditorio_mapeado"
      ];
    },
    preventasEvento() {
      return this.$store.getters["evento/listPreventasEvento"];
    },
    monedaSelectShopEvento() {
      return this.$store.getters["evento/monedaSelectShop"];
    },
    ciudades() {
      return this.$store.getters["ciudad/listCiudades"];
    },
    ciudadesSelect() {
      return this.ciudades.filter(
        (item) => item.id_departamento == this.userSale.departamento
      );
    },
    departamentos() {
      return this.$store.getters["departamento/listDepartamentos"];
    },
    indexMonedaSelectShopEvento() {
      if (this.evento.monedas_evento && this.evento.monedas_evento.length > 0) {
        var index = this.evento.monedas_evento.findIndex(
          (moneda) => moneda.codigo_moneda == this.monedaSelectShopEvento
        );
        if (index == 0) {
          return "";
        } else {
          return index + 1;
        }
      }
    },
    simboloMoneda() {
      if (
        this.indexMonedaSelectShopEvento == "" ||
        this.indexMonedaSelectShopEvento == 0
      ) {
        return this.evento.monedas_evento[0].moneda.simbolo;
      } else {
        return this.evento.monedas_evento[this.indexMonedaSelectShopEvento - 1]
          .moneda.simbolo;
      }
    },
    headers() {
      return [
        {
          text: "TRIBUNA",
          align: "left",
          sortable: true,
          value: "nombreTribuna",
        },
        { text: "LOCALIDAD", value: "nombre" },
        { text: `PRECIO FULL (${this.monedaSelectShopEvento})`, value: "precio" },
        {
          text: `PRECIO CON DESCUENTO (${this.monedaSelectShopEvento})`,
          value: "precioDescuento",
        },
      ];
    },
    headersPreventa() {
      return [
        {
          text: "NOMBRE",
          align: "left",
          value: "nombre",
        },
        {
          text: "FECHA INICIO",
          align: "center",
          value: "fecha_inicio",
        },
        {
          text: "HORA INICIO",
          align: "center",
          value: "hora_inicio",
        },
        {
          text: "FECHA FIN",
          align: "center",
          value: "fecha_fin",
        },
        {
          text: "HORA FIN",
          align: "center",
          value: "hora_fin",
        },
        {
          text: "HORA FIN",
          align: "center",
          value: "hora_fin",
        },
        {
          text: "APLICA A",
          align: "center",
          value: "tipo",
        },
        {
          text: "TIPO DESCUENTO PRECIO",
          align: "center",
          value: "tipo_descuento_precio",
        },
        {
          text: "DESCUENTO PRECIO",
          align: "center",
          value: "descuento_precio",
        },
        {
          text: "TIPO DESCUENTO SERVICIO",
          align: "center",
          value: "tipo_descuento_servicio",
        },
        {
          text: "DESCUENTO SERVICIO",
          align: "center",
          value: "descuento_servicio",
        },
        {
          text: "AUTOMATICA",
          align: "center",
          value: "automatica",
        },
        {
          text: "ACCIONES",
          align: "center",
          value: "actions",
        },
      ];
    },
    tiposEntregaBoletas() {
      var tiposEntregaBoletas = [
        {
          text: "DIGITAL",
          value: "digital",
        },
        {
          text: "FISICA",
          value: "fisica",
        },
        {
          text: "TAQUILLA",
          value: "taquilla",
        },
      ];
      if (this.evento.domicilios != 0) {
        tiposEntregaBoletas.push({
          text: "DOMICILIO",
          value: "domicilio",
        });
      }
      return tiposEntregaBoletas;
    },
    preciosBoletasCarrito() {
      return this.$store.getters["carrito/preciosBoletasCarrito"];
    },
    totalValorBoletasCarrito() {
      var preciosBoletasCarritoMonedaActual = this.$store.getters[
        "carrito/preciosBoletasCarrito"
      ].filter((item) => item.codigo_moneda == this.monedaSelectShopEvento);
      if (
        preciosBoletasCarritoMonedaActual &&
        preciosBoletasCarritoMonedaActual.length > 0
      ) {
        var total = 0;
        for (
          var i = preciosBoletasCarritoMonedaActual.length - 1;
          i >= 0;
          i--
        ) {
          total += preciosBoletasCarritoMonedaActual[i].precio;
        }
        //return total;
        let valor =  this.$store.state.resumenFinalizarCompra.totalventavalor ;
       // console.log(valor);
        return valor;
      } else {
        return 0;
      }
    },
    localidadSelectCarrito() {
      return this.$store.getters["carrito/localidadSelectCarrito"];
    },
    countBoletas() {
      return this.$store.getters["carrito/countBoletas"];
      /* var lasBoletas = [];
        var losPalcos = [];

        for (var i = 0; i < this.listLocalidadesEventoAuditorio_mapeado.length; i++) {
          if (this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id] != null) {
            if (this.listLocalidadesEventoAuditorio_mapeado[i].palco == 1) {
              losPalcos = losPalcos.concat(this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id]);
            } else {
              lasBoletas = lasBoletas.concat(this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id]);
            }
            this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id].length;
          }
        }

        return lasBoletas.length; */
    },
    countPalcos() {
      return this.$store.getters["carrito/countPalcos"];
      /* var lasBoletas = [];
        var losPalcos = [];

        for (var i = 0; i < this.listLocalidadesEventoAuditorio_mapeado.length; i++) {
          if (this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id] != null) {
            if (this.listLocalidadesEventoAuditorio_mapeado[i].palco == 1) {
              losPalcos = losPalcos.concat(this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id]);
            } else {
              lasBoletas = lasBoletas.concat(this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id]);
            }
            this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id].length;
          }
        }

        return losPalcos.length;*/
    },
    dateTimeSaleStart() {
      return this.$store.getters["carrito/dateTimeSaleStart"];
    },
    dateTimeSaleEnd() {
      return this.$store.getters["carrito/dateTimeSaleEnd"];
    },
    userSale() {
      return this.$store.getters["carrito/userSale"];
    },
    busquedaUser() {
      return this.$store.getters["carrito/busquedaUser"];
    },
    tipoIdentificaciones() {
    //  console.log("estoy en el metodo voy pal model")
      return this.$store.getters["user/listTipoIdentificaciones"];
    },
    descripcionTipoIdentificacion() {
      return this.tipoIdentificaciones.filter(
        (item) => item.id === this.userSale.tipo_identificacion
      )[0] ? this.tipoIdentificaciones.filter(
        (item) => item.id === this.userSale.tipo_identificacion
      )[0].descripcion : '';
    },
    validateFechasInicioyFinEvento() {
      if (
        new Date(
          `${this.formatFecha(this.evento.fecha_inicio_venta_puntos)} ${
            this.evento.hora_inicio_venta_puntos
          }`
        ) <= new Date()
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    recibirInformacion(informacion) {
      this.informacionParticipantes = informacion;
    },
    async  cargarPreciosFunciones(id) {
      this.funcionseleccionada = id;
      await  this.$store.dispatch(
      "auditorio_mapeado/getfuncioneslocalidadesFis",
      id
    );
      
     
    }, 
 
    async  traerFechasFunciones() {
      this.$store.state.fechafunciones=null; 
       await  this.$store.dispatch("carrito/getfechafunciones", {
           id: this.evento.id 
        });  
        
  },
    async verResumenCompra() {
      
      var cupon =   'NA';
      if ( this.currentRoute != "evento" || this.evento.fechaValidaInicioInternet ) {
        if (this.$store.getters["user/dataUserLogged"].nombre == null) {
          this.$store.state.modalLogin = true;
        } else {
          if (this.$store.state.carrito.cantBoletaTotal == 0) {
            swal("Carrito Vacio!", "No ha reservado boletas!", "success");
          } else {
            if ( this.entregaBoletas != null && this.entregaBoletas != undefined ) {
              var lasBoletas = [];
              var losPalcos = [];
            
              for ( var i = 0; i < this.listLocalidadesEventoAuditorio_mapeado.length; i++ ) {
                if ( this.$store.state.carrito.cantBoleta[ this.listLocalidadesEventoAuditorio_mapeado[i].id ] != null ) {
                  if ( this.listLocalidadesEventoAuditorio_mapeado[i].palco == 1 ) {
                    losPalcos = losPalcos.concat(
                      this.$store.state.carrito.cantBoleta[ this.listLocalidadesEventoAuditorio_mapeado[i].id ]
                    );
                  } else {
                    lasBoletas = lasBoletas.concat(
                      this.$store.state.carrito.cantBoleta[ this.listLocalidadesEventoAuditorio_mapeado[i].id ]
                    );
                  }
                  this.$store.state.carrito.cantBoleta[ this.listLocalidadesEventoAuditorio_mapeado[i].id ].length;
                }
              }
              if (lasBoletas != [] || losPalcos != []) {
            //  console.log(this.$store.state.carrito);   
              if ( this.$store.state.carrito.cupon != null ) { 
                    cupon =   this.$store.state.carrito.cupon;
                }

                  //Eliminar Duplicidad ID
                  const duplicidadPalcos = new Set(losPalcos);
                  let resultDupliPalcos = [...duplicidadPalcos];

                  const duplicidadBoletas = new Set(lasBoletas);
                  let resultDupliBoletas = [...duplicidadBoletas];
                  var self = this;
                  await this.$store.dispatch("detalle_Boletas_Finalizarcompra", {
                  palcos: resultDupliPalcos.length > 0 ? resultDupliPalcos : '',
                  boletas: resultDupliBoletas.length > 0 ? resultDupliBoletas : '',
                  cupon: cupon.length > 2  ? cupon : 'NA',
                  preventas_automaticas: self.arrayPreventasSelect,
                  tipo: 1,
                  tipoventa: 1
                }).then(()=>{
                  if(this.$store.state.errorDetalleCompra){
                    swal("Parece que algo salio mal!", "Intentalo nuevamente", "error");
                    return '';
                  }else{
                    this.$store.state.showModalFinalizarCompra = true;
                  }
                });




              } else if (lasBoletas == [] && losPalcos == []) {
                swal("Carrito Vacio!", "No ha reservado boletas!", "success");
                return "";
              }
            } else {
              this.modalSelectEntregaBoletas = true;
            }
          }
        }
      } else {
        swal(
          "Error!",
          "Ventas en linea disponible despues de " +
            this.formatDatetimeString(
              this.evento.fecha_inicio_venta_internet_formateada
            ),
          "error"
        );
      }      
    },


    async totalvalorcompra() {
      
      var cupon =   'NA';
      if ( this.currentRoute != "evento" || this.evento.fechaValidaInicioInternet ) {
        if (this.$store.getters["user/dataUserLogged"].nombre == null) {
          this.$store.state.modalLogin = true;
        } else {
          if (this.$store.state.carrito.cantBoletaTotal == 0) {
            //swal("Carrito Vacio!", "No ha reservado boletas!", "success");
          } else {
            if ( this.entregaBoletas != null && this.entregaBoletas != undefined ) {
              var lasBoletas = [];
              var losPalcos = [];
            
              for ( var i = 0; i < this.listLocalidadesEventoAuditorio_mapeado.length; i++ ) {
                if ( this.$store.state.carrito.cantBoleta[ this.listLocalidadesEventoAuditorio_mapeado[i].id ] != null ) {
                  if ( this.listLocalidadesEventoAuditorio_mapeado[i].palco == 1 ) {
                    losPalcos = losPalcos.concat(
                      this.$store.state.carrito.cantBoleta[ this.listLocalidadesEventoAuditorio_mapeado[i].id ]
                    );
                  } else {
                    lasBoletas = lasBoletas.concat(
                      this.$store.state.carrito.cantBoleta[ this.listLocalidadesEventoAuditorio_mapeado[i].id ]
                    );
                  }
                  this.$store.state.carrito.cantBoleta[ this.listLocalidadesEventoAuditorio_mapeado[i].id ].length;
                }
              }
              if (lasBoletas != [] || losPalcos != []) {
            //  console.log(this.$store.state.carrito);   
              if ( this.$store.state.carrito.cupon != null ) { 
                    cupon =   this.$store.state.carrito.cupon;
                }

                  //Eliminar Duplicidad ID
                  const duplicidadPalcos = new Set(losPalcos);
                  let resultDupliPalcos = [...duplicidadPalcos];

                  const duplicidadBoletas = new Set(lasBoletas);
                  let resultDupliBoletas = [...duplicidadBoletas];
                  var self = this;
                  await this.$store.dispatch("detalle_Boletas_Finalizarcompra", {
                  palcos: resultDupliPalcos.length > 0 ? resultDupliPalcos : '',
                  boletas: resultDupliBoletas.length > 0 ? resultDupliBoletas : '',
                  cupon: cupon.length > 2  ? cupon : 'NA',
                  preventas_automaticas: self.arrayPreventasSelect,
                  tipo: 1,
                  tipoventa: 1
                }).then(()=>{
                  if(this.$store.state.errorDetalleCompra){
                    swal("Parece que algo salio mal!", "Intentalo nuevamente", "error");
                    return '';
                  }else{
                   // this.$store.state.showModalFinalizarCompra = true;
                  }
                });




              } else if (lasBoletas == [] && losPalcos == []) {
                swal("Carrito Vacio!", "No ha reservado boletas!", "success");
                return "";
              }
            } else {
             // this.modalSelectEntregaBoletas = true;
            }
          }
        }
      } else {
        swal(
          "Error!",
          "Ventas en linea disponible despues de " +
            this.formatDatetimeString(
              this.evento.fecha_inicio_venta_internet_formateada
            ),
          "error"
        );
      }  
    //  console.log('xxx');    
    },


    uncheckOtherOptions(opcionSeleccionada) {
      this.arrayPreventasLog = this.arrayPreventasLog.map((valor) => {
        if (valor === opcionSeleccionada) {
          return valor;
        } else {
          return false;
        }
      });
    },
    formatNumber(number) {
      return new Intl.NumberFormat("es-CO", {
        minimumFractionDigits: 0,
      }).format(number);
    },
    formatoMoneda(valor) {
      if (valor != null && valor != "") {
        return new Intl.NumberFormat("es-CO").format(valor);
      } else {
        return "";
      }
    },
    getnomtribuna(id) {
      var arrayTribunas = [];
      for (
        var i = 0;
        i < this.listLocalidadesEventoAuditorio_mapeado.length;
        i++
      ) {
        arrayTribunas.push(
          this.listLocalidadesEventoAuditorio_mapeado[i].tribuna
        );
      }
      let filter_tribuna = arrayTribunas.filter((tribuna) => tribuna.id == id);
      if (filter_tribuna[0] == "" || filter_tribuna[0] == null) {
        return "";
      } else {
        return filter_tribuna[0].nombre;
      }
    },
    getnomlocalidad(id) {
      let filter_localidad = this.listLocalidadesEventoAuditorio_mapeado.filter(
        (localidad) => localidad.id == id
      );
      if (filter_localidad[0] == "" || filter_localidad[0] == null) {
        return "";
      } else {
        return filter_localidad[0].nombre;
      }
    },
    getSimboloMoneda(codigo_moneda) {
      let filter_moneda = this.monedas.filter(
        (moneda) => moneda.codigo_moneda == codigo_moneda
      );
      if (filter_moneda[0] == "" || filter_moneda[0] == null) {
        return "";
      } else {
        return filter_moneda[0].simbolo;
      }
    },
    getPuestos(id, localidad) {
      this.idLocalidad = id;
      if (localidad === null) {
        var localidadFilter = null;
        if (
          this.listLocalidadesEventoAuditorio_mapeado &&
          this.listLocalidadesEventoAuditorio_mapeado.length > 0
        ) {
          localidadFilter = this.listLocalidadesEventoAuditorio_mapeado.filter(
            (item) => item.id == id
          );
          this.$store.dispatch(
            "carrito/localidadSelectCarrito",
            localidadFilter
          );
        }
      } else {
        this.$store.dispatch("carrito/localidadSelectCarrito", localidad);
      }
      $("html, body").animate({ scrollTop: 0 }, 1000);
    },
    getLocalidadDescuento(localidad, precio_descuento) {
      if (
        this.$store.getters["evento/listPreventasEvento"] &&
        this.$store.getters["evento/listPreventasEvento"].length > 0
      ) {
        var preventas = this.$store.getters["evento/listPreventasEvento"];
      }
 
       
      var indiceMoneda = 1;
      var total_precio =  parseFloat(localidad.localidad_evento[0].precio_venta)
      var total_servicio = parseFloat(localidad.localidad_evento[0].precio_servicio);
      if (preventas && precio_descuento) {
        for (var j = preventas.length - 1; j >= 0; j--) {
          var preventa = preventas[j];
          if(((preventa.automatica == 0 && this.$store.getters["carrito/arrayPreventasSelect"].includes(preventa.id)) || (preventa.automatica == 1))){
             if (
              (preventa.tipo == 1 || (preventa.tipo == 2 && preventa.ids_tribunas.includes(localidad.id_tribuna.toString())) || (preventa.tipo == 3 && preventa.ids_localidades.includes(localidad.id.toString())) && (this.$route.name != 'evento' || preventa.venta_linea == 1))
            ) {
              if (this.arrayPreventasLog.some((valor) => valor !== false)) {
                if(preventa.automatica==0){
                    if (parseFloat(preventa.descuento_servicio) > 0) {
                    if(preventa.tipo_descuento_servicio == 1){
                      total_servicio = parseFloat(total_servicio) - parseFloat(parseFloat(localidad.localidad_evento[0].precio_servicio) * parseFloat(preventa.descuento_servicio));
                    }else{
                      total_servicio = parseFloat(total_servicio) - parseFloat(preventa.descuento_servicio);
                    }
                  }

                  if (parseFloat(preventa.descuento_precio) > 0) {
                    if(preventa.tipo_descuento_precio == 1){
                      total_precio = parseFloat(total_precio) - parseFloat(parseFloat(localidad.localidad_evento[0].precio_venta) * parseFloat(preventa.descuento_precio));
                    }else{
                      total_precio = parseFloat(total_precio) - parseFloat(preventa.descuento_precio);
                    }
                  }
                }
              }else{
                if(preventa.automatica==1){
                if (parseFloat(preventa.descuento_servicio) > 0) {
                if(preventa.tipo_descuento_servicio == 1){
                  total_servicio = parseFloat(total_servicio) - parseFloat(parseFloat(localidad.localidad_evento[0].precio_servicio) * parseFloat(preventa.descuento_servicio));
                }else{
                  total_servicio = parseFloat(total_servicio) - parseFloat(preventa.descuento_servicio);
                }
              }

              if (parseFloat(preventa.descuento_precio) > 0) {
                if(preventa.tipo_descuento_precio == 1){
                  total_precio = parseFloat(total_precio) - parseFloat(parseFloat(localidad.localidad_evento[0].precio_venta) * parseFloat(preventa.descuento_precio));
                }else{
                  total_precio = parseFloat(total_precio) - parseFloat(preventa.descuento_precio);
                }
              }
            }



              }
             
            }
          }
        }
      }
      var total = total_servicio + total_precio;
      return (total + (total * localidad.localidad_evento[0].impuesto) / 100) <= 0 ? 0 : total + (total * localidad.localidad_evento[0].impuesto) / 100
    },
    verPreventas() {
      this.mostrarPreventas = !this.mostrarPreventas;
    },
    async clearCantBoleta() {
      localStorage.removeItem('mantenerParticipantes'); 
      localStorage.removeItem('boletasReservadasPV'); 
      this.$store.state.carrito.descuentocupon = 0;
      this.$store.state.carrito.cuponestado =0;
      this.$store.state.carrito.vservicio =0;
      this.$store.state.carrito.cupon  = '';
      this.$store.state.carrito.cuponok=false
      this.seleccionado=null;
      //this.$store.state.funcioneshora= this.$store.state.funcioneshora3;
      //this.$store.state.funcioneshora2= this.$store.state.funcioneshora3;
      this.funcionseleccionada=0;
      if (this.preciosBoletasCarrito && this.preciosBoletasCarrito.length > 0) {
        var lasBoletas = [];
        var losPalcos = [];

        for (
          var i = 0;
          i < this.listLocalidadesEventoAuditorio_mapeado.length;
          i++
        ) {
          if (
            this.cantBoleta[
              this.listLocalidadesEventoAuditorio_mapeado[i].id
            ] != null
          ) {
            if (this.listLocalidadesEventoAuditorio_mapeado[i].palco == 1) {
              losPalcos = losPalcos.concat(
                this.cantBoleta[
                  this.listLocalidadesEventoAuditorio_mapeado[i].id
                ]
              );
            } else {
              lasBoletas = lasBoletas.concat(
                this.cantBoleta[
                  this.listLocalidadesEventoAuditorio_mapeado[i].id
                ]
              );
            }
            this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id]
              .length;
          }
        }

        await this.$store.dispatch("carrito/setNullReservas", {
          boletas: lasBoletas,
          palcos: losPalcos,
        });

        this.arrayPreventasLog = this.arrayPreventasLog.map((valor) => {  return false;  });

        this.idLocalidad = "";
        this.limpiarUser();
        this.$store.dispatch("evento/clearCantBoleta");
        this.$store.dispatch("carrito/setNullPreciosBoletasCarrito");
        this.$store.dispatch("carrito/dateTimeSaleStart", null);
        this.$store.dispatch("carrito/dateTimeSaleEnd", null);
        swal("Exito!", "El carrito se vacio correctamente.", "success");
      } else {
        this.arrayPreventasLog = this.arrayPreventasLog.map((valor) => {  return false;  });
        this.$store.dispatch("evento/clearCantBoleta");
        swal("Informacion", "El carrito esta vacio.", "info");
      }
    },
    changeMonedaShopEvento() {
      this.$store.dispatch("evento/changeMonedaShopEvento", {
        codigo_moneda: this.monedaSelectShop,
        evento_id: this.$route.params.evento,
      });
      this.$router.go(this.$router.currentRoute);
    },
    
    async cargarhorario( ) {
      
        this.$store.state.funcioneshora2=null; 
         await this.$store.dispatch("carrito/getfuncioneshora", {
           id_evento:  this.evento.id,
           fecha: this.seleccionado,
        });  
        await  this.$store.dispatch(
      "auditorio_mapeado/getfuncioneslocalidadesFis",
     0
    ); 
    },
    concatTextMoneda(item) {
      return `(${item.moneda.simbolo}) ${item.moneda.descripcion}`;
    },
    async mapasientos(mostrar) {
      if (mostrar) {
        await this.$store.commit("evento/setEvento", this.evento);
      }
      this.dialogmapasientos = mostrar;
    },
    validateDateTimeSale() {
      let fecha = new Date();
      if (this.dateTimeSaleStart != null && this.dateTimeSaleEnd != null) {
        if (
          Date.parse(fecha) >= Date.parse(this.dateTimeSaleStart) &&
          Date.parse(fecha) <= Date.parse(this.dateTimeSaleEnd)
        ) {
        } else if (
          this.preciosBoletasCarrito &&
          this.preciosBoletasCarrito.length > 0 &&
          this.dateTimeSaleStart != null &&
          this.dateTimeSaleEnd != null
        ) {
          this.limpiarUser();
          this.pagoParcial = false;
          this.montoPagoParcial = 0;
          this.idLocalidad = "";
          localStorage.removeItem('mantenerParticipantes'); 
          localStorage.removeItem('boletasReservadasPV'); 
          this.$store.dispatch("carrito/dateTimeSaleStart", null);
          this.$store.dispatch("carrito/dateTimeSaleEnd", null);
          this.$store.dispatch("carrito/setNullPreciosBoletasCarrito");
          this.$store.dispatch("evento/clearCantBoleta");
          swal("Informacion", "El carrito esta vacio.", "info");
        } else {
          this.limpiarUser();
          this.pagoParcial = false;
          this.montoPagoParcial = 0;
          this.idLocalidad = "";
          localStorage.removeItem('mantenerParticipantes'); 
          localStorage.removeItem('boletasReservadasPV'); 
          this.$store.dispatch("carrito/dateTimeSaleStart", null);
          this.$store.dispatch("carrito/dateTimeSaleEnd", null);
          this.$store.dispatch("carrito/setNullPreciosBoletasCarrito");
          this.$store.dispatch("evento/clearCantBoleta");
          swal("Informacion", "El carrito esta vacio.", "info");
        }
      }
    },
    searchUser() {   
      this.tabActive = null      
      //console.log("INICIO: "+this.tabActive)
      if (
        this.userSale.tipo_identificacion &&
        this.userSale.tipo_identificacion != null &&
        this.userSale.identificacion &&
        this.userSale.identificacion != null
      ) {                
        this.$store.dispatch(
          "carrito/searchUserSale",
          this.userSale.identificacion,
          this.show_datos = true
        );
        this.tabActive = this.$store.state.carrito.activarEmailForm
        this.tabactiveboton = true
        //console.log("FIN: "+this.tabActive)
      } else {
        this.tabActive = false
        swal(
          "Informacion!",
          "Debe ingresar tipo de identificacion e identificacion del usuario!",
          "info"
        );
      }
    },
    limpiarUser() {      
      this.tabActive = null
      this.$store.dispatch("carrito/limpiarUserSale", false);
      this.show_datos = false
      this.tabactiveboton = false
    },
    redirect() {
      this.$router.go(0);
    },
    validateSumaPagoMixto() {
      var self = this;
      
      self.montoTarjeta = self.totalValorBoletasCarrito -  self.montoEfectivo;
      self.montoTarjeta =  self.montoTarjeta.toFixed(2); 
      setTimeout(function () {
        if (!self.pagoParcial) {
          if (
            self.montoEfectivo + self.montoTarjeta >
            self.totalValorBoletasCarrito
          ) {
           // self.montoTarjeta = self.totalValorBoletasCarrito / 2;
          //  self.montoEfectivo = self.totalValorBoletasCarrito / 2;
          }
        } else {
          if (
            self.montoEfectivo + self.montoTarjeta >=
            self.totalValorBoletasCarrito
          ) {
            self.montoTarjeta = 0;
            self.montoEfectivo = 0;
          }
        }
      }, 1);
    },
    validateSumaPagoMixtotarjeta() {
      var self = this;
      self.montoEfectivo = self.totalValorBoletasCarrito -  self.montoTarjeta;
      self.montoEfectivo =  self.montoEfectivo.toFixed(2); 
      setTimeout(function () {
        if (!self.pagoParcial) {
          if (
            self.montoEfectivo + self.montoTarjeta >
            self.totalValorBoletasCarrito
          ) {
          //  self.montoTarjeta = self.totalValorBoletasCarrito / 2;
         //   self.montoEfectivo = self.totalValorBoletasCarrito / 2;
          }
        } else {
          if (
            self.montoEfectivo + self.montoTarjeta >=
            self.totalValorBoletasCarrito
          ) {
            self.montoTarjeta = 0;
            self.montoEfectivo = 0;
          }
        }
      }, 1);
    },



    validateMontoPagoParcial() {
      var self = this;
      setTimeout(function () {
        if (self.montoPagoParcial >= self.totalValorBoletasCarrito) {
          self.montoPagoParcial = 0;
        }
      }, 1);
    },
    newSale() {
      var self = this;
     // console.log(self.arrayPreventasSelect);
      swal({
        title: "¿Esta seguro de confirmar la venta?",
        text: "",
        icon: "warning",
        buttons: ["No, cancelar", "Confirmar"],
        closeModal: true,
      }).then((willDelete) => {


        if(this.evento.id_tipo_evento==7 &&  this.funcionseleccionada==0  ) {
                               swal("Funcion no Selecciona!", "Algo salió mal. Limpiar el carrito e iniciar de nuevo!", "success");
          } else{


        if (willDelete) {
          var boletas = [];
          var palcos = [];

          var cupon = 'NA';

          for (
            var i = 0;
            i < self.listLocalidadesEventoAuditorio_mapeado.length;
            i++
          ) {
            if (
              self.$store.state.carrito.cantBoleta[
                self.listLocalidadesEventoAuditorio_mapeado[i].id
              ] != null
            ) {
              if (self.listLocalidadesEventoAuditorio_mapeado[i].palco == 1) {
                palcos = palcos.concat(
                  self.$store.state.carrito.cantBoleta[
                    self.listLocalidadesEventoAuditorio_mapeado[i].id
                  ]
                );
              } else {
                boletas = boletas.concat(
                  self.$store.state.carrito.cantBoleta[
                    self.listLocalidadesEventoAuditorio_mapeado[i].id
                  ]
                );
              }
              self.$store.state.carrito.cantBoleta[self.listLocalidadesEventoAuditorio_mapeado[i].id]
                .length;
            }
          }

          if ( this.$store.state.carrito.cupon != null ) { 
                 cupon =   this.$store.state.carrito.cupon;
          }

          self.$store
            .dispatch("carrito/newSaleFisica", {
              pago_parcial: self.pagoParcial,
              monto_pago_parcial: self.montoPagoParcial,
              entrega_boletas: self.entregaBoletas,
              moneda: self.monedaSelectShopEvento,
              user: self.userSale,
              datosPago: {
                formaPago: self.formaPago,
                formaPagoTarjeta: self.formaPagoTarjeta,
                montoTarjeta: self.montoTarjeta,
                montoEfectivo: self.montoEfectivo,
                codeVoucher: self.codeVoucher,
              },
              boletas: boletas,
              palcos: palcos,
              preventas_automaticas: self.arrayPreventasSelect,
              cupon: cupon.length > 2  ? cupon : 'NA',
              funcionseleccionada : this.funcionseleccionada, 
              observacion: this.observacion,
              participantes: localStorage.getItem('mantenerParticipantes'),
            })
            .then(() => {
              self.limpiarUser();
              self.idLocalidad = "";
              self.pagoParcial = false;
              self.montoPagoParcial = 0;
              self.codeVoucher = null;
              this.observacion = null;
              localStorage.removeItem('mantenerParticipantes'); 
              localStorage.removeItem('boletasReservadasPV'); 
              this.arrayPreventasLog = this.arrayPreventasLog.map((valor) => {  return false;  });
              this.$store.state.carrito.descuentocupon = 0;
              this.$store.state.carrito.cuponestado =0;
              this.$store.state.carrito.vservicio =0;
              this.$store.state.carrito.cupon  = '';
              this.$store.state.carrito.cuponok=false
              this.$store.state.showModalFinalizarCompra = false;
            });
        }

      }



      });
    },
    async changeFormaPago() {
      await  this.totalvalorcompra();
      if (this.formaPago == "mixto" && !this.pagoParcial) {
        this.montoTarjeta = this.totalValorBoletasCarrito / 2;
        this.montoEfectivo = this.totalValorBoletasCarrito / 2;
      } else {
        this.montoTarjeta = 0;
        this.montoEfectivo = 0;
      }
    },
    changeFormaPagoParcial() {
      this.formaPago = "efectivo";
    },
    showModalFactura() {
      this.$store.dispatch("carrito/showFactura", true);
    },
    formatFecha(fecha) {
      if (fecha != null && fecha != "") {
        return moment(fecha).format("YYYY-MM-DD");
      } else {
        return "";
      }
    },
    formatDatetimeString(datetime) {
      if (datetime != null && datetime != "") {
        return moment(datetime).format(
          "dddd Do [de] MMMM [del] YYYY [a las] h:m a"
        );
      } else {
        return "";
      }
    },
    async validarTelefono(event){
        const numtelefono = this.userSale.telefono;
        if(numtelefono.length < 10){
          swal("El Número de teléfono debe tener 10 digitos", '', "warning"); 
          this.tabactiveboton = false
        }else{
          this.tabactiveboton = true
        }
    },
  },
};
</script>
<style>
.check_asignar_pv .accent--text {
  color: #1969ed !important;
  caret-color: #1969ed !important;
}
.f-16 {
  font-size: 1rem;
  cursor: pointer !important;
}
.icon-preview-data i{
  color: #6c757d !important;
}
.icon-preview-data:hover i{
  color: #FAFAFA !important;
}
.container-modal-resumen{
  background-color: white;
  padding: 1.5rem 1rem;
}
@media (max-width: 768px) {
  .container-modal-resumen{padding: 1rem .5rem;}
}
</style>