<template>
    <div>
        <br>
        <button @click="obtenerBoletas" class="btn btn-info" v-if="$router.currentRoute.name == 'ventasEvento'">Actualizar Número Participantes</button>
        <br><br>
      <v-tabs v-model="tab" @change="changeTab" class="st-tab">
        <v-tab v-for="(item, index) in productos.length" :key="index" class="st-tab">
           Participante {{ index + 1 }}
        </v-tab>
      </v-tabs>
  
      <v-card>
        <v-card-text>
            <!-- Mostrando contenido de la pestaña: {{ tab }} -->

            <form @submit.prevent="saveParticipante(tab)">
                <div class="form-row">
                    <div class="form-group col-md-7">
                        <label for="inputNombre">Nombre Completo</label>
                        <input type="text" class="form-control" id="inputNombre" v-model="datos_depurado[tab].nombre" required>
                    </div>
                    <div class="form-group col-md-5">
                        <label for="inputCorreo">Correo Electrónico</label>
                        <input type="email" class="form-control" id="inputCorreo" v-model="datos_depurado[tab].email" required>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="inputCedula">Cedula de Ciudadanía</label>
                        <input type="number" class="form-control" id="inputCedula" v-model="datos_depurado[tab].documento" required>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="inputFechaNacimiento">Fecha de Nacimiento</label>
                        <input type="date" class="form-control" id="inputFechaNacimiento" v-model="datos_depurado[tab].fecha_nacimiento" required>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="inputTalla">Talla de Camiseta</label>
                        <select id="inputTalla" class="form-control" v-model="datos_depurado[tab].talla_camiseta" required>
                            <option selected>Escoger Talla...</option>
                            <option value="XS">XS</option>
                            <option value="S">S</option>
                            <option value="M">M</option>
                            <option value="L">L</option>
                            <option value="XL">XL</option>
                            <option value="XXL">XXL</option>
                        </select>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="inputEPS">EPS</label>
                        <input type="text" class="form-control" id="inputEPS" v-model="datos_depurado[tab].eps" required>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="inputRH">RH</label>
                        <select id="inputRH" class="form-control" v-model="datos_depurado[tab].rh" required>
                            <option selected>Escoger RH...</option>
                            <option value="A+">A positivo (A +)</option>
                            <option value="A-">A negativo (A-)</option>
                            <option value="B+">B positivo (B +)	</option>
                            <option value="B-">B negativo (B-) </option>
                            <option value="AB+">AB positivo (AB+) </option>
                            <option value="AB-">AB negativo (AB-) </option>
                            <option value="O+">O positivo (O+) </option>
                            <option value="O-">O negativo (O-) </option>
                        </select>
                    </div>
                    <div class="form-group col-md-7">
                        <label for="inputContEmer">Contacto de Emergencia</label>
                        <input type="text" class="form-control" id="inputContEmer" v-model="datos_depurado[tab].contacto" required>
                    </div>
                    <div class="form-group col-md-5">
                        <label for="inputNumeroCont">Número de Contacto</label>
                        <input type="number" class="form-control" id="inputNumeroCont" v-model="datos_depurado[tab].num_contacto" required>
                    </div>
                    <div class="form-group col-md-12">
                        <label for="inputParentesco">Parentesco</label>
                        <input type="text" class="form-control" id="inputParentesco" v-model="datos_depurado[tab].parentesco" required>
                    </div>                   
                </div>
                <div class="row">
                    <div class="col col-md-6"></div>
                    <div class="col col-md-6">
                        <button type="submit" class="btn btn-success">Guardar Datos Participante {{ tab+1 }} </button>
                    </div>
                </div>                
                <hr style="border: 2.5px #BC244A solid;">                
                <div class="form-group" style="text-align: left;" v-if="$router.currentRoute.name != 'ventasEvento'">
                    <div class="form-check mp2">
                        <input class="form-check-input" type="checkbox" id="gridCheck" v-model="datos_depurado[tab].politica_uno" required>
                        <label class="form-check-label" for="gridCheck">
                            ACEPTO LA DECLARACIÓN DE EXONERACIÓN DE RESPONSABILIDADES Y ACEPTACIÓN DE TÉRMINOS DE PARTICIPACIÓN Y EL REGLAMENTO DE LA COMPETENCIA.
                        </label>
                    </div><br>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="gridCheck" v-model="datos_depurado[tab].politica_dos" required>
                        <label class="form-check-label" for="gridCheck">
                            ACEPTO la política de privacidad.
                        </label>
                    </div><br>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="gridCheck" v-model="datos_depurado[tab].politica_tres" required>
                        <label class="form-check-label" for="gridCheck">
                            ACEPTO LA POLÍTICA DE ENTREGA DE CAMISETAS: La organización ha previsto un rango amplio de tallas, sin embargo, no garantiza la disponibilidad del 100% de las mismas y están sujetas a inventario.
                        </label>
                    </div><br>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="gridCheck" v-model="datos_depurado[tab].politica_cuatro" required>
                        <label class="form-check-label" for="gridCheck">
                            ACEPTO LA POLÍTICA DE ENTREGA DE KITS: Confirmas y declaras que has leído, entendido y aceptas íntegramente la declaración de exoneración de responsabilidades y términos de participación.
                        </label>
                    </div>
                </div>                
            </form>

            <!-- {{ datos_depurado }} -->

        </v-card-text>
      </v-card>
  
    </div>
  </template>
  
  <script>

  export default {
    name: 'Participantes',
    props: ['informacion'],
    data() {
      return {
        tab: 0,
        productos: [],
        datos_depurado: [],
      };
    },
    created() {
        this.obtenerBoletas()
    },
    methods: {        
        saveParticipante(tab) {

            this.datos_depurado = this.datos_depurado.filter((valor, indice, self) =>
                indice === self.findIndex(obj => (
                    obj.id_boleta === valor.id_boleta
                ))
            );

            localStorage.setItem('mantenerParticipantes', JSON.stringify(this.datos_depurado))
            swal("¡Bien Hecho!", "Datos guardados correctamente.", "success");
        },
        changeTab(tab) {       
            this.tab = tab
        },
        obtenerBoletas() {
            // if(this.$router.currentRoute.name == 'ventasEvento') {

                var data = JSON.parse(localStorage.getItem('boletasReservadasPV'))

            // } else {
            //     var data = localStorage.getItem('newboletasclear')  
            // }              

            if (data) {

            console.log(data)

            this.$router.currentRoute.name == 'ventasEvento' ? this.productos = data : this.productos = data

            if(localStorage.getItem('mantenerParticipantes')) {

                this.datos_depurado = JSON.parse(localStorage.getItem('mantenerParticipantes'))

                this.productos.forEach((item,indice) => {
                    
                    try {
                        
                        if(this.datos_depurado[indice].id_boleta === item) {
                            console.log("ok")
                        } else {
                            throw new Error(item);
                        } 

                    } catch (error) {
                        
                        this.datos_depurado.push(
                            {
                                id_boleta: item,
                                nombre: '',
                                email: '',
                                documento: '',
                                fecha_nacimiento: '',
                                talla_camiseta: '',
                                contacto: '',
                                num_contacto: '',
                                parentesco: '',
                                eps: '',
                                rh: '',
                                politica_uno: false,
                                politica_dos: false,
                                politica_tres: false,
                                politica_cuatro: false,
                                id_venta: 0
                            }
                        )

                    }

                })

                

                if(this.datos_depurado[this.tab].id_boleta === 0) {
                    this.datos_depurado.push(
                        {
                            id_boleta: item,
                            nombre: '',
                            email: '',
                            documento: '',
                            fecha_nacimiento: '',
                            talla_camiseta: '',
                            contacto: '',
                            num_contacto: '',
                            parentesco: '',
                            eps: '',
                            rh: '',
                            politica_uno: false,
                            politica_dos: false,
                            politica_tres: false,
                            politica_cuatro: false,
                            id_venta: 0
                        }
                    )
                    localStorage.setItem('mantenerParticipantes', JSON.stringify(this.datos_depurado))
                }

            } else {
                this.productos.forEach(item => {
                    this.datos_depurado.push(
                        {
                            id_boleta: item,
                            nombre: '',
                            email: '',
                            documento: '',
                            fecha_nacimiento: '',
                            talla_camiseta: '',
                            contacto: '',
                            num_contacto: '',
                            parentesco: '',
                            eps: '',
                            rh: '',
                            politica_uno: false,
                            politica_dos: false,
                            politica_tres: false,
                            politica_cuatro: false,
                            id_venta: 0
                        }
                    )
                });
            }            

            }
        }
    }
  };
  </script>  
<style>

.st-tab {

    /* border-bottom: 1px #BC244A solid !important; */
    border: none !important;

}

</style>