<template style="padding-top: 0px;"  v-if="items.length > 0">
    <div style="padding-top: 6px;"> 
        <v-carousel :continuous="false"   height="auto"      hide-delimiters  hide-delimiter-background
           cycle interval="5000"
           >
            <v-carousel-item v-for="(imagen, index) in items" :key="index" style=" cursor: pointer;"
            @click="redirect ? verGira(imagen.slug) : ''"
            >
                <img :src="imagen.url" alt="Imagen" class="carousel-image">
            </v-carousel-item>
        </v-carousel>
   </div>
</template>


<script>

import axios from 'axios';
import { configApi } from '../../api/configApi';
import { headers } from '../../api/headers';

export default {
    props: [  'redirect'],
    data() {
    return {
      items: []
    };
  },
  async created() {
      await  axios.get(`${configApi.apiUrl}banner_gira`, null, { headers: headers })
        .then(response => { 
             this.items = response.data.items.map(item => ({
                id: item.id,
                nombre: 'Primer Item',
                url: item.imagen1,
                slug:  item.slug,
              }));
            }) ; 



            
  },
    methods: {
        verGira(id) {
            this.$router.push({ name: 'giras', params: { gira: id } });
        },
    },
    mounted() {
    }
};
</script>
<style>
.carousel-image {
    width: 100%;
    height: auto; 
}

.v-carousel .v-window {
    height: auto !important;
}

.custom-carousel .v-window {
    height: auto !important;   cursor: pointer;
}
.custom-carousel {
  cursor: pointer;
}
</style>