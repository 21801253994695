<template>
  <v-app class="text-center">
    <v-container>
      <br />
      <br />
      <br />
      <br />
      <div v-if="tokenActiveAccount != null && !tokenActiveAccount">
        <v-alert :value="true" type="error">
          <h2>
            Tu cuenta no ha podido ser activada, parece que el token caducó o es
            incorrecto.
          </h2>
        </v-alert>
        <br />
        <br />
        <v-btn large @click="$router.push('/')">Ir al inicio</v-btn>
      </div>
      <div v-if="tokenActiveAccount">
        <v-alert :value="true" type="success">
          <h2>
            Tu cuenta ha sido activada con exito. Ya puedes iniciar sesión.
          </h2>
        </v-alert>
        <br />
        <div class="text-center">
          <v-btn color="success" @click="$store.state.modalLogin = true"
            >Iniciar Sesión</v-btn
          >
          <br />
          <br />
          <h5><b>Ó</b></h5>
          <v-btn large @click="$router.push('/')">Ir al inicio</v-btn>
        </div>
      </div>
    </v-container>
  </v-app>
</template>
<script>
export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      //vm.$store.dispatch('fetchAccessToken');
      if (vm.$store.state.user.accessToken != null) {
        next("/");
      }
    });
  },
  created() {
    document.title = "Activar cuenta - TicketShop";
    this.$store.dispatch("activeAccount", this.$route.params.token);
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    tokenActiveAccount() {
      return this.$store.getters.tokenActiveAccount;
    },
  },
};
</script>