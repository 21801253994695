<template>
  <v-app>
    

    <div class="row row-clearfix">
      <div class="col-md-8 col-lg-8 col-sm-8">
        <div class="cont-desc-evento">
          <img :src="datosgira.imagen3" alt="Imagen" class="carousel-image">
          
        </div>

      </div>
      <div class="col-md-4 col-lg-4 col-sm-4" >
        <div class="cont-desc-evento">
          <h2 v-text="datosgira.nombre"  class="header" ></h2>
        
          <br>
            
            <br>
          {{ datosgira.descripcion  }}
        </div>

      </div>


 
          
        
          <div
          class="col-md-4 col-lg-4 col-sm-6 col-xs-12"  v-for="(evento, index) in eventoscargados" :key="index"
          style="padding-right: 3px; padding-left: 3px; padding-top: 0px"
        > 

              
        <router-link :to="{ name: 'evento', params: { evento: evento.slug } }" >
            <div class="cont-evento">
              <v-img
                :key="index" 
                v-for="(imagen, index) in evento.imagens"
                v-if="imagen.nombre.includes('vertical')"
                :src="imagen.url"
                lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                height="100%"
                witdh="100%"
                class="text-right pa-2"
                position="center center"
                contain
                >
                <div class="cont-fecha">
                  <span
                    :class="
                      evento.status === 3
                        ? 'cont-fecha-aplazado'
                        : 'cont-fecha-activo'
                    "
                  >
                    {{
                      evento.status === 3
                        ? "Aplazado"
                        : evento.id == 273 ? "COMPRA AQUÍ" : evento.id == 287 ? "8 AL 17 SEPTIEMBRE" : formatFecha(evento.fecha_evento)
                    }}
                  </span>
                </div>
              </v-img> 
            </div>
        
        </router-link>
        </div>

       


         
 

    </div>




  </v-app>
</template>

<script>

import moment from "moment";
import CarouselGira from "./fragments/Gira.vue";

import axios from 'axios';
import { configApi } from '../api/configApi';
import { headers } from '../api/headers';
import Eventos from "./utils/Eventos.vue";
import _ from 'lodash';
moment.locale("es");
export default {
  components: {
    CarouselGira,
    Eventos,
  },
  name: "Giras", 
  data() {
    return {
       datosgira:[],
       ordenarEventosSelect: ["Precio boleta"],
        desde: null,
        hasta: null,
        menu: false,
        modal: false,
        modal2: false,
        menu2: false,
        precioInicial: 1000,
        precioFinal: 10000000,
        rangoPrecio: [10000, 15000000],
        btnAccesibilidad: false,
        artistasFilter: [],
        tiposEventosFilter: [],
        showSelectsFilter: false,
        widthDivFacebook: 500,
        heightDivFacebook: 600,
        eventosgiras:'',
        eventoscargados:[]

    };
  },
  async  created() {
    const id = this.$route.params.gira; 
     
    await  axios.get(`${configApi.apiUrl}buscar_gira/${id}`, null, {  headers: headers })
        .then(response => { 
          this.datosgira=response.data.items[0];
             
              this.eventosgiras=this.datosgira.eventos; 
            }) ; 


            await  axios.get(`${configApi.apiUrl}eventos_gira/${id}`, null, {  headers: headers })
        .then(response => { 
               this.eventoscargados=response.data.data.data;
            
            }) ; 



  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {

    formatFecha(fecha) {
      if (fecha != null && fecha != "") {
        return moment(fecha).format("MMMM, DD YYYY");
      } else {
        return "";
      }
    },


  },
  
  computed: {

  },
};
</script>

<style>
h6,
li,
p {
  font-size: 0.875rem;
}
.cont-desc-evento {
  background-color: white;
  text-align: center;
  padding: 15px;
  box-shadow: 0px 0px 10px #eaeaea;
  border-radius: 4px;
  border-top: 5px #BC244A solid;
  height: 100%;
}

.cont-mapa-asientos {
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  box-shadow: 0px 0px 10px #dedede;
  border-radius: 5px;
  padding: 10px;
  min-height: 700px;
  background-size: contain;
}

@media (max-width: 1480px) {
  .cont-mapa-asientos {
    min-height: 600px;
  }
}
@media (max-width: 768px) {
  .cont-mapa-asientos {
    min-height: 400px;
  }
  .container-tab {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
}
.cont-mapa-asientos .sub {
  padding: 0px;
}

.cont-preventa-evento {
  background-color: white;
  box-shadow: 0px 0px 10px #dedede;
  border-radius: 5px;
}

.heading-cont-preventa-evento {
  background-color: #1976d2;
  color: white;
  padding: 10px;
  text-align: center;
  margin-bottom: 0px;
}

.sub-cont-preventa-evento {
  border: 1px #c5c5c5 solid;
}

.cont-descuento-evento {
  position: absolute;
  text-align: center;
  top: 60px;
  background-color: red;
  color: white;
  padding: 10px;
  right: 1rem;
  z-index: 2;
  border-radius: 5px;
  box-shadow: 2px 2px 3px 2px #a0a0a0;
}

.container-tab {
  padding-left: 30px;
  padding-right: 30px;
}

.cont-terminos-condiciones {
  border: 1px #bfbfbf solid;
  padding: 10px;
  max-height: 235px;
  overflow-y: scroll;
  background-color: white;
}

.cont-evento {
  background-color: white;
  border: 1px gray solid;
  text-align: right;
  aspect-ratio: 5/6;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 20px;
}
.cont-fecha {
  /*padding:10px;*/
}

.cont-fecha-activo {
  background-color: green;
  position: relative;
  right: -12px;
  padding: 4px 9px;
  color: white;
  border-radius: 3px;
  box-shadow: -1px 3px 4px 0px #000000;
}

.cont-fecha-aplazado {
  background-color: #ffff00;
  position: relative;
  right: -12px;
  padding: 4px 9px;
  color: #000000;
  border-radius: 3px;
  box-shadow: -1px 3px 4px 0px #000000;
}

.cont-fecha-cancelado {
  background-color: red;
  position: relative;
  right: -12px;
  padding: 4px 9px;
  color: #000000;
  border-radius: 3px;
  box-shadow: -1px 3px 4px 0px #000000;
}

.list-condiciones-evento li {
  text-transform: capitalize;
}
.carousel {
  height: auto !important;
}
.mt-50 {
  margin-top: 50px;
}
.color-slide-table {
  color: black !important;
  margin-left: auto;
  margin-right: auto;
}
.container-evento {
  padding-bottom: 4rem;
}
.btn-finalizar-compra {
  background-color: #1867c0 !important;
  color: white !important;
}
.skeleton-banner-principal-evento{
  height: 100%;
  aspect-ratio: 880/448;
}
.img-skeleton .v-skeleton-loader__image{
  height: 500px;
}
.skeleton-banner-principal-evento .v-skeleton-loader .v-skeleton-loader__image, .skeleton-banner-principal-evento .v-skeleton-loader{
  height: 100%;
}
.tag-img-evento{
  position: absolute;
  display: block;
  right: 0;
  top: .4rem;
  width: 170px;
  z-index: 1;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.container-modal-resumen{
  background-color: white;
  padding: 1.5rem 1rem;
}
@media (max-width: 768px) {
  .container-modal-resumen{padding: 1rem .5rem;}
}
 
 
.custom-calendar .flatpickr-months {
  background-color: #f0f0f0;
  border-bottom: 1px solid #ddd;
  width: 600px;
  height: 40px;
}

.custom-calendar .flatpickr-days .flatpickr-day {
  background-color: #fff;
  color: #333;
}

.custom-calendar .flatpickr-days .flatpickr-day:hover {
  background-color: #eee;
}

.custom-calendar .flatpickr-weekdays .flatpickr-weekday {
  color: #999;
}

.custom-calendar .flatpickr-calendar.open {
  border-color: #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.flatpickr-input {
  display: none;
}
.disabled-date {
  background-color: #696565;
  color: #111010;
}
.custom-flatpickr {
 
}
</style>