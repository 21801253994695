<template>
  <v-app style="  padding-top: 0px">
    <div v-if="eventos.length > 0">
      <div id="listEventos" class="row row-clearfix">
       

        <div
          class="col-md-4 col-lg-4 col-sm-6 col-xs-12"  v-for="(imagengira, index) in girasinicio" :key="index+30"
          style="padding-right: 3px; padding-left: 3px; padding-top: 0px"
        > 
 
              
        <router-link :to="{ name: 'giras', params: { gira:  imagengira.slug }}" >
            <div class="cont-evento">
              <v-img
                :key="index+30" 
                :src="imagengira.url" 
                lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                height="100%"
                witdh="100%"
                class="text-right pa-2"
                position="center center"
                contain
                >
                <div class="cont-fecha">
                  <span 
                  >Gira
                  </span>
                </div>
              </v-img> 
            </div>
        
        </router-link>
        </div>

       
        <div
          class="col-md-4 col-lg-4 col-sm-6 col-xs-12"
          :key="index"
          v-for="(evento, index) in eventos"
          style="padding-right: 3px; padding-left: 3px; padding-top: 0px"
        > 
          <router-link :to="{ name: 'evento', params: { evento: evento.slug } }">
            <div class="cont-evento">
              <v-img
                :key="index"
                v-for="(imagen, index) in evento.imagens"
                v-if="imagen.nombre.includes('vertical')"
                :src="imagen.url"
                lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                height="100%"
                witdh="100%"
                class="text-right pa-2"
                position="center center"
                contain
              >
                <div class="cont-fecha">
                  <span
                    :class="
                      evento.status === 3
                        ? 'cont-fecha-aplazado'
                        : 'cont-fecha-activo'
                    "
                  >
                    {{
                      evento.status === 3
                        ? "Aplazado"
                        : evento.id == 273 ? "COMPRA AQUÍ" : evento.id == 287 ? "8 AL 17 SEPTIEMBRE" : formatFecha(evento.fecha_evento)
                    }}
                  </span>
                </div>
              </v-img>
              <v-img
                v-if="evento.imagens.length <= 0"
                src="/img/logo-ticketshop.ca82ecc6.png"
                lazy-src="/img/logo-ticketshop.ca82ecc6.png"
                height="100%"
                witdh="100%"
                class="text-right pa-2"
              >
                <div class="cont-fecha">
                  <span
                    :class="
                      evento.status == 3
                        ? 'cont-fecha-aplazado'
                        : evento.status == 2
                        ? 'cont-fecha-cancelado'
                        : 'cont-fecha-activo'
                    "
                  >
                    {{
                      evento.status == 3
                        ? "Aplazado"
                        : evento.status == 2
                        ? "Cancelado"
                        : formatFecha(evento.fecha_evento)
                    }}
                  </span>
                </div>
              </v-img>

              <div class="cont-fecha"></div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="paginationEventos.page"
          :length="pagesEventos"
        ></v-pagination>
      </div>
    </div>
    <div v-else class="text-center animated fadeInUp">
      <h1 style="color: #8c8c8c">
        <i class="material-icons" style="font-size: 100px">hourglass_empty</i
        ><br />
        Upss. No se encontraron eventos...
      </h1>
    </div>
  </v-app>
</template>
<script>
import moment from "moment";
import axios from 'axios';
import { configApi } from '../../api/configApi';
import { headers } from '../../api/headers';
export default {
  props: [
    "precioInicio",
    "precioFin",
    "artistasFilter",
    "tiposEventosFilter",
    "fechaInicio",
    "fechaFin",
    "ciudadeseventosFilter"
  ],
  data() {
    return {
      giras: []
    };
  },
  mounted() {
    // this.getImagenVal = this.eventos;
  },
  async beforeCreate() {
   
  },
  async created() {
    // this.$store.dispatch('evento/getImagenesEvento', this.eventos.id);
    this.newvasgiras();
  

  },
  data() {
    return {
      // getImagenVal: [],
      // urlImg: [],
      firstPinter: true,
      girasinicio:[],
    };
  },
  watch: {
    paginationEventos: {
      async handler() {
        if(this.firstPinter){
          this.firstPinter = false;
        }else{
          $("html, body").animate({ scrollTop: $('#listEventos').offset().top }, 350);
        }        
         this.$store.dispatch("evento/filterEventos", {
          page: this.paginationEventos.page,
          precio_inicio: this.precioInicio,
          precio_fin: this.precioFin,
          artistas: this.artistasFilter,
          tiposEvento: this.tiposEventosFilter,
          fecha_inicio: this.fechaInicio,
          fecha_fin: this.fechaFin,
          ciudadeseventos:this.ciudadeseventosFilter
          
        }); 
      },
      deep: true,
    },
  },
  computed: {
    eventos() {
      return this.$store.getters["evento/listEventos"].reverse();
    },
    paginationEventos() {
      return this.$store.getters["evento/pagination"];
    },
    pagesEventos() {
      return this.$store.getters["evento/pages"];
    },
    totalItemCount() {
      return this.$store.getters["evento/totalItemCount"];
    },
    loading() {
      return this.$store.getters["evento/loading"];
    },
    
  },
  methods: {

    newvasgiras(){
    
        axios.get(`${configApi.apiUrl}banner_gira`, null, { headers: headers })
        .then(response => { 
            
             this.girasinicio = response.data.items.map(item => ({
                id: item.id,
                nombre: 'Primer Item',
                url: item.imagen2,
                slug:  item.slug,
              }));
               
            }) ;
             
      }
      ,
    changePage(page) {
      this.$store.commit("evento/changePage", page);
      this.$store.dispatch("evento/listEventosStatus", {
        page: page,
        status: 1,
      });
    },
    formatFecha(fecha) {
      if (fecha != null && fecha != "") {
        return moment(fecha).format("MMMM, DD YYYY");
      } else {
        return "";
      }
    },
  },
};
</script>
<style scoped>
.cont-evento {
  transition: all 0.5s ease;
}
.cont-evento:hover {
  box-shadow: 0px 0px 10px #a0a0a0;
  border-radius: 5px;
  border-color: white;
  transition: all 0.5s ease;
}
.cont-evento {
  background-color: white;
  border: 1px gray solid;
  text-align: right;
  aspect-ratio: 5/6;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 20px;
}
.cont-fecha {
  /*padding:10px;*/
}
.cont-fecha span {
  background-color: green;
  position: relative;
  right: -12px;
  padding: 4px 9px;
  color: white;
  border-radius: 3px;
  box-shadow: -1px 3px 4px 0px #000000;
}

.cont-fecha-activo {
  background-color: green;
  position: relative;
  right: -12px;
  padding: 4px 9px;
  color: white;
  border-radius: 3px;
  box-shadow: -1px 3px 4px 0px #000000;
}

.cont-fecha-aplazado {
  background-color: #ffff00 !important;
  position: relative;
  right: -12px;
  padding: 4px 9px;
  color: #000000 !important;
  border-radius: 3px;
  box-shadow: -1px 3px 4px 0px #000000;
}

.cont-fecha-cancelado {
  background-color: red;
  position: relative;
  right: -12px;
  padding: 4px 9px;
  color: #000000;
  border-radius: 3px;
  box-shadow: -1px 3px 4px 0px #000000;
}
</style>