<template>
  <v-app id="footer-ticket"> 
    <footer class="mt-0">
      <div class="footer row row-clearfix">
        <div class="col-md-3 col-lg-3 cont-mini-footer mb-5">
          <h5 class="title-cont-mini-footer"><b>Siguenos</b></h5>
          <br>
          <v-layout row wrap class="list-social-links">
            <v-flex lg3 md3 sm3 xs3>
              <a href="https://www.facebook.com/TICKETSHOPCOL/" target="_blank">
                <font-awesome-icon :icon="['fab', 'facebook-square']" size="2x"/>
              </a>
            </v-flex>
            <v-flex lg3 md3 sm3 xs3>
              <a href="https://twitter.com/TicketShopCol" target="_blank">
                <font-awesome-icon :icon="['fab', 'twitter']" size="2x"/>
              </a>
            </v-flex>
            <v-flex lg3 md3 sm3 xs3>
              <a href="https://www.instagram.com/ticketshopcol/" target="_blank">
                <font-awesome-icon :icon="['fab', 'instagram']" size="2x"/>
              </a>
            </v-flex>
            <v-flex lg3 md3 sm3 xs3>
              <a href="https://www.youtube.com/channel/UCmYdWEN9mbjAR27TuP9gEwg" target="_blank">
                <font-awesome-icon :icon="['fab', 'youtube']" size="2x"/>
              </a>
            </v-flex>
          </v-layout>
        </div>
        <div class="col-md-3 col-lg-3 cont-mini-footer">
          <h5 class="title-cont-mini-footer"><b>Quienes somos</b></h5>
          <br>
          <ul class="list-unstyled list-menu-links">
            <li>
              <router-link :to="{path: '/'}" title="Inicio">
                Inicio
              </router-link>
            </li>
            <li>
              <router-link :to="{path: '/mision'}" title="Mision">
                Mision y Visión
              </router-link>
            </li>

            <li>
              <router-link :to="{path: '/comunicados'}" title="Comunicados">
                Comunicados
              </router-link>
            </li>
            <!--<li>
              <router-link :to="{path: '/contacto'}" title="Contactanos">
                Contactanos
              </router-link>
            </li>-->
            <br>
            <li>
              <router-link :to="{path: '/puntosVenta'}" title="PUNTOS DE VENTA">
                PUNTOS DE VENTA
              </router-link>
            </li>
          </ul>
        </div>

        <div class="col-md-3 col-lg-3 cont-mini-footer">
          <h5 class="title-cont-mini-footer"><b>TicketShop</b></h5>
          <br>
          <ul class="list-unstyled list-menu-links">
            <li>
              <router-link :to="{path: '/terminoscondiciones'}" title="Terminos y Condiciones">
                Terminos y Condiciones
              </router-link>
            </li>
            <li>
              <a href="https://centrodeayuda.ticketshop.com.co" title="Solicitud de Peticiones, Quejas y Reclamos">
                Solicitud de Peticiones, Quejas y Reclamos
              </a>
            </li>
            <li>
              <router-link :to="{path: '/politicatratamiento'}"
                           title="Politica de tratamiento de proteccion de datos personales">
                Politica de tratamiento de proteccion de datos personales
              </router-link>
            </li>
            <li>
              <a href="https://www.sic.gov.co/asesoria-en-linea">SIC</a>
            </li>
          </ul>
        </div>
        <div class="col-md-3 col-lg-3 cont-mini-footer text-center">
          <h5 class="title-cont-mini-footer"><b>Si continua en esta pagina acepta los</b></h5>
          <router-link :to="{path: '/terminoscondiciones'}" title="Terminos y Condiciones" class="title-cont-mini-footer">Terminos y condiciones de uso</router-link>
        </div>
      </div><hr style="color: #292E40; background: #292E40;">
      <section class="aliados slider">
        <div class="slide div-slide-alidos" title="" v-for="aliado in aliados">
          <img :src="aliado.src" :class="cssRefresh ? 'img-slide-aliados' : ''">
        </div>
      </section>
      <br>
      <section>
        <div class="copyright">
          <router-link :to="{path: '/'}" class="navbar-brand" title="TicketShop" tag="a">
            <img class="logo" src="../../../public/images/logo-ticketshop-footer.png" height="60px" width="80px">
          </router-link>
          <small>TicketShop.com {{ new Date().getFullYear() }} Colombia. Todos los derechos
          </small><br>

        </div>
      </section>

    </footer>
  </v-app>
</template>
<script>
import axios from 'axios';
import { configApi } from '../../api/configApi';
import { headers } from '../../api/headers';
export default {
  mounted() {
    
  },
  async beforeCreate() {
    await    axios.get(`${configApi.apiUrl}buscarimagenaliados`,null,{headers: headers})
      .then(response => {
        this.ftaliados = response.data.data
        
        this.aliados =   this.ftaliados.map(item => { 
                return { src: item.url };
              }); 

        this.cssRefresh = true;

          $(document).ready(function () {
            $('.aliados').slick({
              slidesToShow: 6,
              slidesToScroll: 1,
              autoplay: true,
              autoplaySpeed: 3000,
              arrows: false,
              dots: false,
              vertical: false,
              pauseOnHover: false,
              responsive: [{
                breakpoint: 768,
                settings: {
                  slidesToShow: 4
                }
              }, {
                breakpoint: 520,
                settings: {
                  slidesToShow: 3
                }
              }],
              variableWidth: true,
            });
          });
      })
  },
  data() {
    return {
      aliados: [],
      ftaliados: [],
      cssRefresh: false
    }
  }
};
</script>
<style>
footer {
  background-color: #161925;
  margin-top: 40px;
}
#footer-ticket .v-application--wrap{
  min-height: auto;
}
.footer {
  padding: 35px 15px 0px 15px;
  color: white;
}

.list-social-links a {
  color: white;
}

.list-social-links a:hover {
  color: white;
  opacity: .8;
}

.title-cont-mini-footer {
  text-transform: uppercase;
  font-size: 16px;
  color: #BC244A;

}

.list-menu-links a {
  color: white;
}

.list-menu-links a:hover {
  color: white;
  opacity: .8;
}

.cont-mini-footer {
  margin-bottom: 15px;
}

.copyright {
  color: white;
  text-align: center;
  padding: 10px 10px;
  background-color: #1E212D;
}

div.div-slide-alidos {
  width: 145px !important;
  text-align: center !important;
}

div > img.img-slide-aliados {
  width: 120px !important;
  height: 90px !important;
}
.v-application a{
  color: white !important;
}
</style>