import Vue from 'vue';
import Vuex from 'vuex';
import {mutations} from './mutations';
import {actions} from './actions';
import {getters} from './getters';
import createPersistedState from 'vuex-persistedstate';
import user from './modules/user';
import tipoevento from './modules/tipoevento';
import evento from './modules/evento';
import artista from './modules/artista';
import cliente from './modules/cliente';
import auditorio from './modules/auditorio';
import pais from './modules/pais';
import departamento from './modules/departamento';
import ciudad from './modules/ciudad';
import condicion from './modules/condicion';
import genero from './modules/genero';
import preciomonedas from './modules/preciomonedas';
import puntoventa from './modules/puntoventa';
import tipocosto from './modules/tipocosto';
import temporada from './modules/temporada';
import moneda from './modules/moneda';
import imagen from './modules/imagen';
import tribuna from './modules/tribuna';
import localidad from './modules/localidad';
import fila from './modules/fila';
import puesto from './modules/puesto';
import solicitudevento from './modules/solicitudevento';
import auditorio_mapeado from './modules/auditorio_mapeado';
import auditorio_mapeado_tribuna from './modules/auditorio_mapeado_tribuna';
import auditoriaevento from './modules/auditoriaevento';
import carrito from './modules/carrito';
import pqr from './modules/pqr';
import contacto from './modules/contacto';
import comunicados from './modules/comunicados';


Vue.use(Vuex);

export const store = new Vuex.Store({
     modules:{
        user,
        tipoevento,
        evento,
        artista,
        cliente,
        auditorio,
        pais,
        departamento,
        ciudad,
        condicion,
        genero,
        preciomonedas,
        puntoventa,
        tipocosto,
        temporada,
        moneda,
        imagen,
        tribuna,
        localidad,
        fila,
        puesto,
        solicitudevento,
        auditorio_mapeado,
        auditorio_mapeado_tribuna,
        auditoriaevento,
        carrito,
        pqr,
         contacto,
         comunicados,
     },
     state:{
        accessToken: null,
        loggingIn: false,
        loginError: null,
        loader: true,
        validateToken: null,
        tokenResetPassword: null,
        modalLogin: false,
        modalRegister: false,
        tokenActiveAccount: null,
        sha: null,
        obtener_refventa: null,
        init_point: null,
        modalFactura: false,
        urlimagenlocalidad : null,
        filalocalidad : null,
        puestosfila : null,
        dataPayment: null,
        responseDataPayment: null,
        responseDataPaymentV2: null,
        errorDetalleCompra: false,
        showModalFinalizarCompra: false,
        validarotp: false,
        formulariopago:true, 
        resultadopago:false, 
        resumenFinalizarCompra: null,
        cupon:null,
        protectionValue:0,
        totalValue:0,
	    	pgwidget:null,
        productId:null,
        sold:null,
        emailactivacion: null,
        funcioneshora:[],
        funcioneshora2:[{
             id: null,
                hora:null,
                aforo: null
              }],
              funcioneshora3:[{
                id: null,
                hora:null,
                aforo: null
              }],
            funcioneslocalidades:[],
            fechafunciones:[],
            fechafunciones1:[],

    },
    mutations,
    actions,
    getters,
    plugins: [createPersistedState({
        paths: ['user', 'carrito']
    })]

});