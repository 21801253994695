<template>
  <div class="container-evento">
    <meta name="description"
      content="Llega un mega concierto para el inicio de la navidad en el castillo marroquín, presentaciones en vivo de Blessd, westcol y 11 artistas en escena  "
      v-if="evento.id == 330">
    <meta name="keywords"
      content="blessd, westcol, nampa basico, lion fiah, nath, soley, fumaratto, favid valencia, new fest, muzza, miguel holguin b2b maicol suarez, nina caballero, regaetton, guaracha, tech tribal, urbano, navidad, chia, bogota, tu boleta, concierto, fiesta, planes en bogota, planes en chia, diciembre, novenas, iluminados, velitas, milenial, jovenes, sisabe, blessdoficial, las morras, palabras sobran, medallo, dos problemas, ojos azules, quien tv, ojitos rojos, mi gata, tendencia global, que duro, stiven mesa londoño, fechas, el bendito, warner music latina, jw world music, siempre blessd, trap latino, reguetón, regueton, feid, peso pluma, karol g"
      v-if="evento.id == 330">
    <meta name="author" content="ticketshop colombia comercializadora de franquicias sas" v-if="evento.id == 330">
    <TipoEventos v-if="evento" :horizontal="true" :select="evento.id_tipo_evento" :redirect="false"></TipoEventos>
    <div v-if="evento">



      <div class="row row-clearfix">
        <div class="col-md-12 col-lg-12 col-sm-12">

          <div class="row  ">
            <div class="col-md-8 col-lg-8 col-sm-8">

              <v-tabs fixed-tabs show-arrows slider-color="#BC244A" light>
                <v-tab @click="tab = 'general'">
                  Informacion general
                </v-tab>
                <v-tab @click="tab = 'mapa'">
                  Mapa
                </v-tab>
              </v-tabs>




              <div v-if="tab == 'general' && preventasEvento">
                <div class="cont-descuento-evento" v-if="preventasEvento.length > 0">
                  <h4>{{ preventasEvento[preventasEvento.length - 1].porcentaje_descuento_precio }} %</h4>
                  <h5>DCTO</h5>
                  <h6>Valido hasta</h6>
                  <h6>{{ formatFecha(preventasEvento[preventasEvento.length - 1].fecha_fin) }}</h6>
                </div>
                <!--<Carousel class="carousel" :items="imagenesEventoCarousel" :redirect="false"></Carousel> -->
                <v-img v-if="imagenesEventoCarousel.length > 0" :key="imagenesEvento.length"
                  style="background: linear-gradient(0.20turn, #F8F7F4, #F8F7F499, transparent);"
                  lazy-src="@/assets/icons/logo-ticketshop-carousel.png" :src="imagenesEventoCarousel[0].imagen.url"
                  class="w-100" alt="" />
                <v-img v-else src="@/assets/icons/logo-ticketshop-carousel.png" style="filter: blur(3px)" />
              </div>


              <template v-if="tab == 'mapa'">
                <div style="background: #ffffff ; padding: 3px 3px 3px 3px;">
                  <h2 class="text-center">{{ getAuditorio(evento.id_auditorio) }}</h2>
                  <iframe v-if="mapaauditorio != ''" class="iframe w-100" :src="mapaauditorio" width="100%"
                    frameborder="0" style="border:0; min-height: 500px;" allowfullscreen></iframe>

                </div>
              </template>


            </div>

            <div class="col-md-4 col-lg-4 col-sm-4">

              <div class="cont-desc-evento">


                <h2 v-text="evento.nombre" class="header"></h2>
                <br>
                <h6><b>Fecha:</b> {{ dateTimeToDate(evento.fecha_evento) }}</h6>
                <h6><b>Apertura de puertas:</b> {{ formatTime(evento.hora_apertura) }}</h6>
                <h6><b>Horario inicio:</b> {{ formatTime(evento.hora_inicio) }}</h6>
                <h6><b>Horario de salida:</b> {{ formatTime(evento.hora_finalizacion) }}</h6>
                <h6><b>Lugar:</b> {{ getAuditorio(evento.id_auditorio) }}</h6>
                <br>
                <div class="row row-clearfix" v-if="false">
                  <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 pr-5 pl-3 text-center pb-4">
                    <v-select v-model="monedaSelectShop" item-value="codigo_moneda" :item-text="concatTextMoneda"
                      :items="evento.monedas_evento" label="Cambiar moneda de compra" prepend-icon="payment"
                      @change="changeMonedaShopEvento" success persistent-hint></v-select>
                  </div>
                </div>

                <v-layout row wrap text-center v-if="false">
                  <v-btn @click="verResumenCompra()"
                    style="color: white; background-color: blue;"><v-icon>shopping_cart</v-icon>&nbsp; VER RESUMEN
                    CARRITO</v-btn>
                  <br><br><br>
                  <v-flex lg7 md6 sm12 xs12 mb-3>



                  </v-flex>
                  <v-flex lg5 md6 sm12 xs12 mb-3>
                    <v-chip v-if="evento.venta_linea == 1 && evento.status == 1" @click="clearCantBoleta">
                      <v-icon center color="#907b16">remove_shopping_cart</v-icon>
                      <b>&nbsp;VACIAR CARRITO</b>
                    </v-chip>
                  </v-flex>
                </v-layout><br>
                <button class="btn btn-primary" onclick="location.href='#ubicacion-objetivo'"> <v-icon
                    style="color:white;">shopping_cart</v-icon>&nbsp; COMPRAR AHORA </button>
                <h2 style="display:none;" v-if="evento.id == 258">Boletos o Entradas</h2>

                <br><br>
                <router-link to="/terminoscondiciones" target="_blank">Antes de realizar una compra lee nuestros
                  terminos y
                  condiciones aqui
                </router-link>




              </div>



            </div>
          </div>
        </div>
      </div>






      <div class="container-tab">
        <div id="ubicacion-objetivo"></div>
        <template v-if="tab == 'general'">
          <v-alert :value="evento.estado === 3" type="warning">
            Venta on-line a partir del <b>{{
              formatDatetimeString(`${formatFechaClasic(evento.fecha_inicio_venta_internet)}
              ${evento.hora_inicio_venta_internet}`)
            }}</b>.
          </v-alert>
          <v-alert :value="evento.estado === 3" type="warning">
            Venta en todos los expendios fisicos del pais desde el <b>{{
              formatDatetimeString(`${formatFechaClasic(evento.fecha_inicio_venta_puntos)}
              ${evento.hora_inicio_venta_puntos}`)
            }} </b>.
          </v-alert>
          <br>
          <div class="row row-clearfix ">
            <div
              :class="idLocalidad != '' || $store.state.funcioneshora.length > 0 ? 'col-md-12 col-lg-12 mb-12' : 'col-md-6 col-lg-6 mb-4'">
              <div class="cont-mapa-asientos">
                <div v-if="mostrarmapa" class="cont-mapa-asientos"
                  :style="{ backgroundImage: 'url(\'' + evento.auditorio_mapeado.imagen + '\')' }"
                  style="cursor:pointer;" @click="mapasientos(true);">
                </div><br>
                <div class="sub" id:="boletas">
                  <BoletasxLocalidad :key="keyBoletasxLocalidad" :pagos="pagos" :fisico="false" v-if="idLocalidad != ''"
                    :idlocalidad="idLocalidad" :idEvento="evento.id" :monedaEvento="monedaSelectShopEvento"
                    :indexMonedaSelectShopEvento="indexMonedaSelectShopEvento" :simboloMoneda="simboloMoneda"
                    :monedasEvento="evento.monedas_evento"></BoletasxLocalidad>
                </div>
                <v-layout row justify-space-between>


                  <v-flex align-self-end v-if="mostrarmapa"><br>
                    <v-chip @click="mapasientos(true)">
                      <v-icon left color="#907b16">search</v-icon>
                      <b>VER MAPA DE PUESTOS</b>
                    </v-chip>
                  </v-flex>

                  <br>
                  <div class="row  " v-if="!mostrarmapa">



                    <div class="col-md-6 col-lg-6 mb-4">
                      SELECCIONA UNA FECHA

                      <flat-pickr v-model="selectedDate" :modelValue="selectedDate" :config="flatpickrConfig"
                        class="custom-calendar "></flat-pickr>

                    </div>

                    <div class="col-md-6 col-lg-6 mb-4">
                      <label style="text-align: center; " v-if="$store.state.funcioneshora.length > 0">Funciones
                        disponibles para: {{ fechaselectedDate }} </label>
                      <div style=" margin-top: 20px; " v-for="item in $store.state.funcioneshora" :key="item.id">
                        <div class="row mb-4"
                          style="   box-shadow: 0px 10px 10px -6px  rgb(184, 179, 166); border-radius: 15px;">
                          <div class=" col-3"
                            style=" color:#ffffff;  background-color: #51a2eee5; border-radius: 15px 0px 15px 0px; ">
                            <h6>HORA</h6>
                          </div>

                          <div class=" col-5">
                            <h5>Desde {{ item.horainicio }}, Hasta {{ item.horafinaliza }} </h5>
                          </div>



                          <div class=" col-4"><button class="btn btn-success"
                              :disabled="($store.state.carrito.cantBoletaTotal !== 0 && funcionseleccionada !== item.id) || item.estado == 0"
                              @click="cargarPreciosFunciones(item.id)"> Comprar </button>
                          </div>

                        </div>
                      </div>

                    </div>

                    <v-dialog v-model="modalVisible" max-width="500px">
                      <v-card>
                        <v-card-title>
                          <!-- <span class="headline">Valores</span>-->
                        </v-card-title>
                        <v-card-text>


                          <div class="row  mb-4  "
                            style="box-shadow: 0px 10px 10px -6px  rgb(184, 179, 166); border-radius: 15px;"
                            v-for="item in $store.state.funcioneslocalidades" :key="item.id">
                            <div class=" col-8">{{ item.nombre }}</div>
                            <div class=" col-4">


                              <button class="btn btn-success"
                                @click="ejecutarGetPuestos(item.id_localidad)">Seleccionar</button>
                            </div>
                          </div>






                        </v-card-text>
                        <v-card-actions>
                          <v-btn class="btn btn-primary" text @click="cerrarModalFunciones">Cerrar</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>



                    <v-dialog v-model="dialogotablaprecios" max-width="100%">
                      <v-card>
                        <v-card-title>
                          <!-- <span class="headline">Valores</span>-->
                        </v-card-title>
                        <v-card-text>


                          <img :src="evento.url_imagen" :alt="evento.nombre" v-if="evento.url_imagen">







                        </v-card-text>
                        <v-card-actions>
                          <v-btn class="btn btn-primary" text @click="dialogotablaprecios = false">Cerrar</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>




                  </div>





                  <v-dialog v-if="activeModalAsientos" v-model="dialogmapasientos">
                    <div class="cont-desc-evento">
                      <v-card>
                        <div class="text-center   " style="padding-top: 5px;">
                          <h3> Selecciona tu localidad </h3>
                        </div>
                        <hr> <v-card-text  >
                          <center class="area_mapeadaAuditorio2" >
                            <img   :key="keyBoletasxLocalidad" :src="evento.auditorio_mapeado.imagen" usemap="#map">
                          </center>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>

                          <v-btn color="green darken-1" @click="mapasientos(false);">
                            OK
                          </v-btn>
                        </v-card-actions>
                      </v-card>

                    </div>
                  </v-dialog>
                </v-layout>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="cont-preventa-evento">
                <div v-if="!mostrarmapa">
                  <img @click="dialogotablaprecios = true" :src="evento.url_imagen" :alt="evento.nombre"
                    v-if="evento.url_imagen" class="w-100" style="cursor: pointer">
                  <TablaEvento v-else @showModal="dialogmapasientos = $event" />
                </div>
                <div v-if="mostrarmapa">
                  <img @click="dialogmapasientos = true" :src="evento.url_imagen" :alt="evento.nombre"
                    v-if="evento.url_imagen" class="w-100" style="cursor: pointer">
                  <TablaEvento v-else @showModal="dialogmapasientos = $event" />
                </div>


                <!-- <img @click="dialogmapasientos = true" src="@/assets/images/hiper_concierto_cartagena.jpeg" :alt="evento.nombre" v-if="evento.id == 59" style="max-width:100%;width:100%;cursor:pointer;" title="Ver mapa de puestos">
                <img @click="dialogmapasientos = true" src="@/assets/images/concierto_de_reyes.jpeg" :alt="evento.nombre" v-if="evento.id == 58" style="max-width:100%;width:100%;cursor:pointer;" title="Ver mapa de puestos">
                <img @click="dialogmapasientos = true" src="@/assets/images/preventa_vive_la_salsa_3.jpeg" :alt="evento.nombre" v-if="evento.id == 43" style="max-width:100%;width:100%;cursor:pointer;" title="Ver mapa de puestos">
                <img @click="dialogmapasientos = true" src="@/assets/images/tigres_preventa2.jpeg" :alt="evento.nombre" v-if="evento.id == 41" style="max-width:100%;width:100%;cursor:pointer;" title="Ver mapa de puestos">
                <img @click="dialogmapasientos = true" src="@/assets/images/despecho_cajica.jpeg" :alt="evento.nombre" v-if="evento.id == 45" style="max-width:100%;width:100%;cursor:pointer;" title="Ver mapa de puestos">
                <img @click="dialogmapasientos = true" src="@/assets/images/imagen_preventa_51.jpeg" :alt="evento.nombre" v-if="evento.id == 51" style="max-width:100%;width:100%;cursor:pointer;" title="Ver mapa de puestos">
                <img @click="dialogmapasientos = true" src="@/assets/images/despecho_preventa.jpeg" :alt="evento.nombre" v-if="evento.id == 53" style="max-width:100%;width:100%;cursor:pointer;" title="Ver mapa de puestos">
                <v-tabs v-if="evento.id != 58 && evento.id != 59 && evento.id != 43 && evento.id != 41 && evento.id != 45 && evento.id != 51 && evento.id != 53"
                    v-model="tabpreventa"
                    fixed-tabs
                    show-arrows
                    tabs-item-max-width="100%"
                    center-active>
                  <v-tab class="color-slide-table" @click="getPreventa(-1)" v-if="listLocalidadesEventoAuditorioGeneral_mapeado.length > 0">
                    Preventa
                  </v-tab>
                  <v-tab class="color-slide-table" @click="getPreventa(0)">
                    Venta normal
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tabpreventa" v-if="evento.id != 58 && evento.id != 59 && evento.id != 43 && evento.id != 41 && evento.id != 45 && evento.id != 51 && evento.id != 53">
                  <v-tab-item v-if="listLocalidadesEventoAuditorioGeneral_mapeado.length > 0">
                    <div class="sub-cont-preventa-evento">
                      <div class="table-responsive">
                        <table class="table table-striped">
                          <thead>
                          <tr>
                            <th>Tribuna </th>
                            <th>Localidad</th>
                            <th>Fecha</th>
                            <th>Precio venta</th>
                            <th>Precio servicio</th>
                            <th>Total ({{ monedaSelectShopEvento }})</th>
                          </tr>
                          </thead>
                          <tbody style=" cursor: pointer;">
                          <tr :key="index" v-for="(localidad, index) in listLocalidadesEventoAuditorioGeneral_mapeado"
                              @click="mapasientos(true)">
                            <td>{{ localidad.tribuna.nombre }}</td>
                            <td>{{ localidad.nombre }}</td>
                            <td><b>Del </b> {{ localidad.fecha_inicio }} <b> al </b> {{ localidad.fecha_fin }}</td>
                            <td>{{ simboloMoneda + getPrecioVentaLocalidad(localidad) }}</td>
                            <td>{{ simboloMoneda + getPrecioServicioLocalidad(localidad) }}</td>
                            <td>{{ simboloMoneda + localidad.total }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </v-tab-item>
                  <v-tab-item>
                    <div class="sub-cont-preventa-evento">
                      <div class="table-responsive">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Tribuna</th>
                              <th>Localidad</th>
                              <th>Precio venta</th>
                              <th>Precio servicio</th>
                              <th>Total ({{ monedaSelectShopEvento }})</th>
                            </tr>
                          </thead>
                          <tbody style=" cursor: pointer ">
                          <tr :key="index2" v-for="(localidad2, index2) in listLocalidadesEventoAuditorio_mapeado"
                              @click="mapasientos(true)">
                            <td>{{ localidad2.tribuna.nombre }} </td>
                            <td>{{ localidad2.nombre }}</td>
                            <td>{{ simboloMoneda + getPrecioVentaLocalidad(localidad2) }}</td>
                            <td>{{ simboloMoneda + getPrecioServicioLocalidad(localidad2) }}</td>
                            <td>{{ simboloMoneda + getLocalidadDescuento(localidad2, false) }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </v-tab-item> 
                </v-tabs-items> -->
                <hr>
                <v-layout row wrap text-center>
                  <v-flex lg6 md6 sm12 xs12 mb-3>

                    <a class="btn btn-info" style="color: white; background-color: green;"
                      @click="enviaraCarrito()"><v-icon style="color: white; ">shopping_cart</v-icon>&nbsp; FINALIZAR
                      COMPRA</a>



                    <!--   <a class="btn btn-info"   style="color: white; background-color: green;" :href="'/carts/' + evento.slug"><v-icon style="color: white; " >shopping_cart</v-icon>&nbsp;  FINALIZAR COMPRA</a>
                      <v-btn @click="verResumenCompra()" style="color: white; background-color: blue;"><v-icon>shopping_cart</v-icon>&nbsp; VER RESUMEN CARRITO</v-btn>-->
                  </v-flex>
                  <v-flex lg6 md6 sm12 xs12 mb-3>
                    <v-chip v-if="evento.venta_linea == 1 && evento.status == 1" @click="clearCantBoleta">
                      <v-icon center color="#907b16">remove_shopping_cart</v-icon>
                      <b>&nbsp;VACIAR CARRITO</b>
                    </v-chip>
                  </v-flex>
                </v-layout>
                <br>

              </div>
              <!--<form method="post" action="https://www.paymentsbakery.co/gateway2/index.php" id='btnPayU2' v-html="elhtml2"></form>-->
            </div>

          </div>
          <div class="cont-desc-evento" style="text-align: left;">
            <div class="row row-clearfix">
              <div class="col-md-6 col-lg-6 col-sm-6">
                <h6><b>Codigo PULEP:</b> {{ evento.codigo_pulep }}</h6>
                <br>
                <h6>EMPRESA RESPONSABLE</h6>
                <h6><b>{{ evento.cliente.nombrerazon }}</b></h6>
                <h6><b>NIT:</b> {{ evento.cliente.Identificacion }}</h6>
                <h6><b>Dirección:</b> {{ evento.cliente.direccion }} {{ evento.cliente.ciudad }},
                  {{ evento.cliente.departamento }}.</h6>
                <div class="mt-5" v-if="evento.artists.length > 0">
                  <h4><b>Artistas</b></h4>
                  <h6 v-for="artist of evento.artists" :key="artist.id" v-text="artist.nombre"></h6>
                </div>
              </div>

              <div class="col-md-6 col-lg-6 col-sm-6">
                <h4 class="mb-3 mt-5" style="text-align: left;"><b>Condiciones y restricciones</b></h4>
                <ul class="list-condiciones-evento">
                  <li v-if="condicionesEvento != null" :key="index" v-for="(condicion, index) in condicionesEvento"
                    style="margin-bottom: .5rem;">
                    {{ condicion.condicion.descripcion }}
                  </li>
                </ul>
              </div>


              <div class="col-md-12 col-lg-12 col-sm-12">
                <hr>

                <h4 class="mt-4"><b>Terminos y Condiciones de Uso</b></h4>
                <div class="cont-terminos-condiciones">
                  <p class="mb-0" :key="index" v-for="(p, index) in evento.descripcion.split('\n')">
                    <template v-if="p.split('•').length > 1">
                      <span :key="index2" v-for="(span, index2) in p.split('•')">{{ span.length > 1 ? '•' + span :
                        '' }}</span>
                    </template>
                    <template v-else>
                      <template v-if="p.split('\r').length > 1">
                        <span :key="index3" v-for="(span, index3) in p.split('\r')">{{ span.length > 1 ? span : '' }}
                          <p v-if="span.length > 1" />
                        </span>
                      </template>
                      <template v-else>
                        {{ p }}
                        <p />
                      </template>
                    </template>
                  </p>
                </div>
              </div>
            </div>
          </div>


          <div class="row row-clearfix mt-5 mb-3">
            <div class="col-md-3 col-lg-3 col-sm-6" :key="index" v-for="(evento, index) in eventosDestacados">
              <router-link :to="{ name: 'evento', params: { evento: evento.slug } }">
                <div class="cont-evento position-relative">
                  <span class="tag-img-evento"
                    :class="evento.status == 3 ? 'cont-fecha-aplazado' : evento.status == 2 ? 'cont-fecha-cancelado' : 'cont-fecha-activo'">
                    {{ evento.status == 3 ? 'Aplazado' : evento.status == 2 ? 'Cancelado' :
                      formatFecha(evento.fecha_evento) }}
                  </span>
                  <v-img v-if="imagen.nombre.includes('vertical')" :key="index"
                    v-for="(imagen, index) in evento.imagens" :src="imagen.url"
                    lazy-src="/img/logo-ticketshop.ca82ecc6.png" height="100%" witdh="100%" class="text-right pa-2"
                    position="center center" contain>
                  </v-img>
                  <v-img v-if="evento.imagens.length <= 0" src="/img/logo-ticketshop.ca82ecc6.png"
                    lazy-src="/img/logo-ticketshop.ca82ecc6.png" height="100%" witdh="100%" class="text-right pa-2">
                  </v-img>
                </div>
              </router-link>
            </div>
          </div>
        </template>





      </div>
      <v-dialog v-model="modalSelectEntregaBoletas" max-width="500px" persistent>
        <form @submit.prevent="pagar()" action="#" autocomplete="off">
          <v-card>
            <v-card-title>
              <div class="text-center col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <img src="/images/logo-ticketshop.png" style="max-width:150px;width:150px;">
              </div>
            </v-card-title>
            <v-container>
              <v-card-text>
                <h5 class="text-left"><b>
                    <v-icon>theaters</v-icon>
                    ELIGE COMO QUIERES TUS BOLETAS</b></h5>
                <p>Elige el medio por el cual se te hara entrega de las boletas y/o palcos de tu compra.</p>
                <v-divider></v-divider>
                <v-layout wrap row>
                  <v-flex lg12 md12 sm12 xs12 mb-3>
                    <v-radio-group v-model="entregaBoletas">
                      <v-radio value="digital">
                        <template v-slot:label>
                          <div>
                            ENTREGA DIGITAL
                            <v-tooltip top color="black">
                              <template v-slot:activator="{ on }">
                                <v-icon color="gray" v-on="on">
                                  info
                                </v-icon>
                              </template>
                              Este medio enviara tus boletas y/o palcos a tu correo electronico.
                            </v-tooltip>
                          </div>
                        </template>
                      </v-radio>
                      <!--<v-radio
                      value="fisica"
                    >
                      <template v-slot:label>
                        <div>
                          ENTREGA FISICA
                          <v-tooltip top color="black">
                            <template v-slot:activator="{ on }">
                              <v-icon color="gray"
                                v-on="on"
                              >
                                info
                              </v-icon>
                            </template>
                            Deberas acercarte a un punto de venta autorizado del evento a reclamar tus boletas y/o palcos de manera impresa.
                          </v-tooltip>
                        </div>
                      </template>
                    </v-radio>
                      <v-radio v-if="evento.domicilios"
                      value="domicilio"
                    >
                      <template v-slot:label>
                        <div>
                          ENTREGA A DOMICILIO
                          <v-tooltip top color="black">
                            <template v-slot:activator="{ on }">
                              <v-icon color="gray"
                                v-on="on"
                              >
                                info
                              </v-icon>
                            </template>
                            Tus boletas y/o palcos seran entregados a tu domicilio de manera impresa.
                          </v-tooltip>
                        </div>
                      </template>
                    </v-radio>
                      <v-radio
                      value="taquilla"
                    >
                      <template v-slot:label>
                        <div>
                          ENTREGA EN TAQUILLA
                          <v-tooltip top color="black">
                            <template v-slot:activator="{ on }">
                              <v-icon color="gray"
                                v-on="on"
                              >
                                info
                              </v-icon>
                            </template>
                            Deberas reclamar tus boletas y/o palcos en la taquilla del evento.
                          </v-tooltip>
                        </div>
                      </template>
                    </v-radio>-->
                    </v-radio-group>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-container>
            <hr>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn type="submit" color="success" :disabled="entregaBoletas == null">FINALIZAR</v-btn>
              <v-btn color="blue darken-1" @click="modalSelectEntregaBoletas = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-dialog>
    </div>
    <div v-else>
      <div class="d-flex flex-wrap h-100">
        <div class="skeleton-banner-principal-evento col-12 col-md-8">
          <v-skeleton-loader type="image" class="img-skeleton"></v-skeleton-loader>
        </div>
        <div class="col-12 col-md-4">
          <div class="skeleton-fb h-100">
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </div>
        </div>
        <div class="d-flex flex-wrap w-100 mt-5 px-3 px-md-4">
          <div class="col-12 col-md-6">
            <v-skeleton-loader type="image" class="img-skeleton" style="min-height: 500px"></v-skeleton-loader>
          </div>
          <div class="col-12 col-md-6">
            <div class="mb-4">
              <v-skeleton-loader type="image" class="img-skeleton" style="min-height: 500px"></v-skeleton-loader>
            </div>
            <div class="mb-4">
              <v-skeleton-loader type="image" class="img-skeleton" style="min-height: 500px"></v-skeleton-loader>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="$store.state.showModalFinalizarCompra" max-width="700">
      <div class="container-modal-resumen" v-if="$store.state.resumenFinalizarCompra">
        <h2 class="text-center mb-5">Resumen de compra</h2>
        <table width="100%" border="0" cellspacing="0" cellpadding="0" class="table">
          <tr :key="index" v-for="(product, index) in $store.state.resumenFinalizarCompra.productos">
            <td style="text-align:left; font-weight: bold;">&nbsp;{{ product.nombre }}</td>
            <td style="text-align:right; font-weight: bold;">&nbsp;{{ product.total }}</td>
          </tr>
          <tr>
            <td style="text-align:left; font-weight: bold;">&nbsp; </td>
            <td style="text-align:right; font-weight: bold;">&nbsp; </td>
          </tr>
          <tr>
            <td style="text-align:center; font-weight: bold; background:#F3F3F3;">&nbsp; TOTAL:</td>
            <td style="text-align:right; font-weight: bold; background:#F3F3F3;">&nbsp;
              {{ $store.state.resumenFinalizarCompra.totalVenta }}</td>
          </tr>

        </table>

        <!-- <div class="text-center mb-5 pb-5">
          <h5 class="text-center mb-5">ELIGE UN MÉTODO PARA REALIZAR TU PAGO</h5>
          

          <v-select
                  v-model="selectedmetodo" 
                  :items="metodospago"
                  label="Cambiar método de pago"
                  prepend-icon="payment"  
                  success
                  persistent-hint
                ></v-select>
                <p>Has seleccionado: {{ selectedmetodo }}</p>
        </div> -->
        <div class="text-center mb-5 pb-5">
          <v-btn color="primary" style="min-width: 250px" class="btn-finalizar-compra"
            :disabled="!acceptTD || !acceptTyC" v-if="evento.venta_linea == 1 && evento.status == 1" @click="pagar()">
            <v-icon center>payment</v-icon>&nbsp;IR A PAGAR
          </v-btn>
        </div>
        <!-- <div class="d-flex flex-wrap">
          <img src="https://static.placetopay.com/placetopay-logo.svg" class="col-12 col-md-6 p-0 mb-4 mb-md-0" alt="Logo placetopay" style="max-height:150px;">
          <img src="@/assets/images/mediosPago.jpeg" class="col-12 col-md-6 p-0" alt="medios de pago placetopay">
        </div> -->
      </div>
    </v-dialog>
  </div>
</template>
<script>
import TipoEventos from "./fragments/TipoEventos.vue";
import Carousel from "./fragments/Carousel.vue";
import BoletasxLocalidad from "./utils/BoletasxLocalidad.vue";
import TablaEvento from './Templates/Evento/TablaEvento';

import moment from "moment";
import { faSleigh } from "@fortawesome/free-solid-svg-icons";

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/l10n/es';
import 'flatpickr/dist/themes/material_blue.css';
import _ from 'lodash';
moment.locale("es");

export default {
  components: {
    TipoEventos,
    Carousel,
    BoletasxLocalidad,
    TablaEvento,
    flatPickr,
  },
  data() {
    return {
      type: 'month',
      start: new Date(),
      end: new Date(),
      typeOptions: [
        { text: 'Day', value: 'day' },
        { text: '4 Day', value: '4day' },
        { text: 'Week', value: 'week' },
        { text: 'Month', value: 'month' },
        { text: 'Custom Daily', value: 'custom-daily' },
        { text: 'Custom Weekly', value: 'custom-weekly' }
      ],
      selectedDate: new Date(),
      seleccionado: null,
      enabledDates: [],
      fechaselectedDate: '',
      seleccionadohora: '',
      actualizarfecha: true,
      menu: false,
      acceptTyC: false,
      acceptTD: false,
      dialogotablaprecios: false,
      dataCompare: null,
      tab: "general",
      tabpreventa: 0,
      modalSelectEntregaBoletas: false,
      entregaBoletas: "digital",
      keyBoletasxLocalidad: 0,
      mostrarmapa: true,
      modalVisible: false,
      horario: [],
      flatpickrStyle: {
        width: '500px',
        height: '70px',
      },
      funcionseleccionada: 0,
      ventas: [
        {
          localidad: "palco F.A.M.E",
          aforo: 32,
          precio: 456000,
          servicio: 200000,
        },
        {
          localidad: "palco F.A.M.E",
          aforo: 32,
          precio: 456000,
          servicio: 200000,
        },
        {
          localidad: "palco F.A.M.E",
          aforo: 32,
          precio: 456000,
          servicio: 200000,
        },
        {
          localidad: "palco F.A.M.E",
          aforo: 32,
          precio: 456000,
          servicio: 200000,
        },
        {
          localidad: "palco F.A.M.E",
          aforo: 32,
          precio: 456000,
          servicio: 200000,
        },
        {
          localidad: "palco F.A.M.E",
          aforo: 32,
          precio: 456000,
          servicio: 200000,
        },
        {
          localidad: "palco F.A.M.E",
          aforo: 32,
          precio: 456000,
          servicio: 200000,
        },
      ],
      keyPuntoventas: "",
      headersPuntoventa: [
        {
          text: "Nombre Comercial",
          align: "left",
          value: "punto_ventum.nombre_razon",
        },
        {
          text: "Dirección",
          value: "punto_ventum.direccion",
        },
        {
          text: "Ciudad",
          value: "punto_ventum.id_ciudad",
        },
        {
          text: "Zona/Region",
          value: "punto_ventum.zona",
        },
        {
          text: "Telefono",
          value: "punto_ventum.telefono",
        },
        {
          text: "Responsable",
          value: "punto_ventum.responsable",
        },
      ],
      rowperpageitems: [5, 10, 25, { text: "Todos", value: -1 }],
      idLocalidad: "",
      dialogmapasientos: false,
      elhtml2: "",
      monedaSelectShop: null,
      activeModalAsientos: false,
    };
  },
  watch: {
    // evento(){
    //   this.$store.dispatch('auditorio_mapeado/listLocalidadesAuditorio_mapeado',this.evento.id_auditorio_mapeado);
    // },
    async evento() {
      this.$store.commit('evento/setImagenesEvento', null);
      if (this.evento) {
        this.$store.dispatch(
          "evento/listPreventasEvento",
          this.evento.id
        );
        this.$store.dispatch(
          "auditorio_mapeado/listLocalidadesEventoAuditorioGeneral_mapeado",
          this.evento.id
        );
        await this.$store.dispatch("evento/changeMonedaShopEvento", {
          codigo_moneda: this.monedaSelectShopEvento,
          evento_id: this.evento.id,
        });
        this.$store.dispatch(
          "evento/getCondicionesEvento",
          this.evento.id
        );
        this.$store.dispatch(
          "evento/listPreventasEvento",
          this.evento.id
        );
        this.$store.dispatch(
          "evento/getpuntoventasEvento",
          this.evento.id
        );
        this.$store.dispatch(
          "auditorio_mapeado/listLocalidadesEventoAuditorio_mapeado",
          this.evento.id
        );
        this.mostrarmapa = true;
        if (this.evento.id_tipo_evento == 7) {
          this.mostrarmapa = false;
        }

        if (this.monedaSelectShopEvento === null && this.evento.monedas_evento.length > 0) {
          this.$store.commit(
            "evento/changeMonedaShopEvento",
            this.evento.monedas_evento[0].codigo_moneda
          );
          this.monedaSelectShop = this.monedaSelectShopEvento;
        } else {
          this.monedaSelectShop = this.monedaSelectShopEvento;
        }
        this.$store.dispatch("evento/getImagenesEvento", this.evento.id);
        $("html, body").animate({ scrollTop: $('body').offset().top }, 1);
        this.activeModalAsientos = false;
        if (this.evento && this.evento.slug != this.$route.params.evento) {
          this.$store.dispatch(
            "auditorio_mapeado/listLocalidadesEventoAuditorio_mapeado",
            this.evento.id
          );
          this.$store.dispatch(
            "auditorio_mapeado/listLocalidadesEventoAuditorioGeneral_mapeado",
            this.evento.id
          );
        }
        if (this.idLocalidad && this.evento.localidades_evento) {
          let element = this.evento.localidades_evento.find(
            (e) => e.id_localidad == parseInt(this.idLocalidad)
          );
          if (!element) {
            this.idLocalidad = "";
          }
        }
        setTimeout(() => {
          this.activeModalAsientos = true;
        }, 500);
        this.keyBoletasxLocalidad += 1;
      }
    },
    dialogmapasientos() {
      setTimeout(() => {
        var self = this;
        $(".area_mapeadaAuditorio2").append(
          $("<textarea />")
            .html(
              this.evento.auditorio_mapeado.area_mapeada.replaceAll("<br>", "")
            )
            .text()
            .replaceAll("&nbsp;", "")
        );
        $(".maparea").click(function (e) {
          e.preventDefault();
          var texthref = e.currentTarget.href;
          var arrayhref = texthref.split("/");
          self.getPuestos(arrayhref[arrayhref.length - 1], null);
          window.location.hash = "boletas";
          self.dialogmapasientos = false;
        });
      }, 200);
    },
    "$route.params.evento": function (from, to) {
      this.onMounted();
      window.scroll(0, 0);
    },
  },
  async beforeCreate() {
    this.$store.dispatch("ciudad/listCiudades");
    await this.$store.dispatch("auditorio/listAuditorios");
    //await this.$store.dispatch("evento/getEvento", this.$route.params.evento);
    await this.$store.dispatch("evento/listEventosStatus", {
      page: 1,
      status: 1,
    });

    // this.$store.dispatch('auditorio_mapeado/listLocalidadesAuditorio_mapeado',this.$store.getters['evento/evento'].id_auditorio_mapeado);
    // this.$store.dispatch('auditorio_mapeado/listLocalidadesEventoAuditorio_mapeado', this.$route.params.evento);
    // this.$store.dispatch('auditorio_mapeado/listLocalidadesEventoAuditorioGeneral_mapeado', this.$route.params.evento);

    if (this.evento && this.evento.status != 1 && this.evento.status != 3) {
      this.$router.push({ path: "/" });
    }

    if (this.userAuth.id_rol != null && this.userAuth.id_rol == 5) {
      this.$router.push({ name: "misEventos" });
    }
  },
  async mounted() {
    $("html, body").animate({ scrollTop: $('body').offset().top }, 1);
    await this.onMounted();
    window.setInterval(() => {
      this.validateDateTimeSale();
    }, 3000);
    // this.mapasientos(true);

    // Obtener la estructura desde localStorage
    const estadoGuardado = localStorage.getItem('estadoVuex');

    // Asegurarse de que haya un estado guardado y establecerlo en Vuex si existe
    if (estadoGuardado) {
      this.$store.replaceState(JSON.parse(estadoGuardado));
      localStorage.removeItem('estadoVuex');
    }
    // console.log( this.$store.state);
    if (this.evento.id_tipo_evento == 7) {
      this.traerFechasFunciones();
    }




  },
  beforeUpdate() {
    // document.title = `${this.evento ? this.evento.nombre : 'Evento Ticketshop'} - TicketShop`;

    if (this.evento.id == 330) {
      document.title = 'WHITE MERRY CHRISTMAS BLESSD CASTILLO MARROQUIN 16 DICIEMBRE';
    } else {
      document.title = `${this.evento ? this.evento.nombre : 'Evento Ticketshop'} - TicketShop`;
    }

    if (this.idLocalidad != "") {
      document.getElementsByClassName(
        "cont-mapa-asientos"
      )[0].style.backgroundImage = "";
    }
  },
  updated() {
    /*if (this.elhtml2!="")
    {
      $('#btnPayU2').submit();
    }*/
  },
  methods: {
    miFuncion(selectedDates, dateStr, instance) {

      this.$store.dispatch("carrito/getfuncioneshora", {
        id_evento: this.evento.id,
        fecha: dateStr,
      });
      this.selectedDate = dateStr
      this.fechaselectedDate = dateStr
    },

    async traerFechasFunciones() {
      this.$store.state.fechafunciones = null;
      await this.$store.dispatch("carrito/getfechafunciones", {
        id: this.evento.id
      });

      // this.cambiarFecha();

    },
    cambiarFecha: _.once(function () {
      window.setInterval(() => {
        this.actualizarfecha = false;
        //  if(!this.actualizarfecha )  {   

        let fechaDada = new Date(this.evento.fecha_evento);
        let fechaActual = new Date();
        fechaDada.setHours(0, 0, 0, 0);
        fechaActual.setHours(0, 0, 0, 0);
        if (fechaDada > fechaActual) {
          this.fechaselectedDate = fechaDada
          this.selectedDate = fechaDada; // Actualizar el valor del campo de fecha
        }
        //  console.log(this.$store.state.fechafunciones);
        // console.log(fechaDada);
        // this.selectedDate = this.evento.fecha_evento; // Actualizar el valor del campo de fecha
      }, 1000);

      //  }
    }),

    async cargarPreciosFunciones(id) {
      this.modalVisible = true;
      this.$store.state.funcioneslocalidades = null;
      this.funcionseleccionada = id;
      await this.$store.dispatch("carrito/getfuncioneslocalidades", {
        id: id
      });

    },

    cerrarModalFunciones() {
      this.modalVisible = false;
    },
    ejecutarGetPuestos(id) {
      var self = this;
      self.getPuestos(id, null);
      window.location.hash = "boletas";
      this.modalVisible = false;
    },
    async handleDateClick(date) {

      await this.$store.dispatch("carrito/getfuncioneshora", {
        id_evento: this.evento.id,
        fecha: this.selectedDate,
      });
    },
    async onMounted() {
      var self = this;
      await this.$store.dispatch("evento/getEvento", this.$route.params.evento);

      //$('.carousel').height($('.cont-desc-evento').height());

      var self = this;
      setTimeout(() => {
        self.activeModalAsientos = true;
      }, 500);
      setTimeout(() => {
        $(".area_mapeadaAuditorio2").append(
          $("<textarea />")
            .html(
              self.$store.getters["evento/evento"].auditorio_mapeado
                .area_mapeada
            )
            .text()
            .replace("&nbsp;", "")
        );
        $(".maparea").click(function (e) {
          e.preventDefault();
          var texthref = e.currentTarget.href;
          var arrayhref = texthref.split("/");
          self.getPuestos(arrayhref[arrayhref.length - 1], null);
          window.location.hash = "boletas";
          //$("html, body").animate({ scrollTop: $('#boletas').offset().top }, 1);
          self.dialogmapasientos = false;
          //$("html, body").animate({ scrollTop: 700 }, 1000);
        });
      }, 900);
    },
    async clearCantBoleta() {
      this.$store.state.carrito.descuentocupon = 0;
      this.$store.state.carrito.cuponestado = 0;
      this.$store.state.carrito.vservicio = 0;
      this.$store.state.carrito.cupon = '';
      this.$store.state.carrito.cuponok = false
      this.$store.state.funcioneshora = [];
      localStorage.removeItem('funcionselected');
      localStorage.removeItem('boletasReservadasPV');
      localStorage.removeItem('mantenerParticipantes');
      if (this.preciosBoletasCarrito && this.preciosBoletasCarrito.length > 0) {
        var lasBoletas = [];
        var losPalcos = [];

        for (
          var i = 0;
          i < this.listLocalidadesEventoAuditorio_mapeado.length;
          i++
        ) {
          if (
            this.cantBoleta[
            this.listLocalidadesEventoAuditorio_mapeado[i].id
            ] != null
          ) {
            if (this.listLocalidadesEventoAuditorio_mapeado[i].palco == 1) {
              losPalcos = losPalcos.concat(
                this.cantBoleta[
                this.listLocalidadesEventoAuditorio_mapeado[i].id
                ]
              );
            } else {
              lasBoletas = lasBoletas.concat(
                this.cantBoleta[
                this.listLocalidadesEventoAuditorio_mapeado[i].id
                ]
              );
            }
            this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id]
              .length;
          }
        }

        await this.$store.dispatch("carrito/setNullReservas", {
          boletas: lasBoletas,
          palcos: losPalcos,
        });

        this.idLocalidad = "";
        this.$store.dispatch("evento/clearCantBoleta");
        this.$store.dispatch("carrito/setNullPreciosBoletasCarrito");
        this.$store.dispatch("carrito/dateTimeSaleStart", null);
        this.$store.dispatch("carrito/dateTimeSaleEnd", null);
        swal("Exito!", "El carrito se vacio correctamente.", "success");
      } else {
        var lasBoletas = [];
        var losPalcos = [];
        this.idLocalidad = "";
        this.$store.dispatch("evento/clearCantBoleta");
        swal("Informacion", "El carrito esta vacio.", "info");
      }
    },
    dateTimeToDate(datetime) {
      if (datetime != null && datetime != "") {
        return moment(datetime).format("YYYY/MM/DD");
      } else {
        return "";
      }
    },
    formatFecha(fecha) {
      if (fecha != null && fecha != "") {
        return moment(fecha).format("MMMM, DD YYYY");
      } else {
        return "";
      }
    },
    formatTime(time) {
      if (time != null && time != "") {
        return moment(time, "HH:mm:ss").format("LT");
      } else {
        return "";
      }
    },
    formatDatetimeString(datetime) {
      if (datetime != null && datetime != "") {
        return moment(datetime).format(
          "dddd Do [de] MMMM [del] YYYY [a las] h:m a"
        );
      } else {
        return "";
      }
    },
    formatNumber(number) {
      return new Intl.NumberFormat("es-CO", {
        minimumFractionDigits: 0,
      }).format(number);
    },
    getCiudad(id) {
      let filter_ciudad = this.ciudades.filter((ciudad) => ciudad.id == id);
      if (filter_ciudad[0] == "" || filter_ciudad[0] == null) {
        return "";
      } else {
        return filter_ciudad[0].descripcion;
      }
    },
    getPuestos(id, localidad) {
      if (this.evento && this.evento.status === 3) {
        return false;
      }

      this.idLocalidad = id;
      if (localidad === null) {
        var localidadFilter = null;
        if (
          this.listLocalidadesEventoAuditorio_mapeado &&
          this.listLocalidadesEventoAuditorio_mapeado.length > 0
        ) {
          localidadFilter = this.listLocalidadesEventoAuditorio_mapeado.filter(
            (item) => item.id == id
          );
          this.$store.dispatch(
            "carrito/localidadSelectCarrito",
            localidadFilter
          );
        }
      } else {
        this.$store.dispatch("carrito/localidadSelectCarrito", localidad);
      }
    },
    getAuditorio(id) {
      let filter_auditorio = [];
      if (this.auditorios) {
        filter_auditorio = this.auditorios.filter(
          (auditorio) => auditorio.id == id
        );
      }
      if (filter_auditorio[0] == "" || filter_auditorio[0] == null) {
        return "";
      } else {
        return filter_auditorio[0].nombre;
      }
    },
    async mapasientos(mostrar) {
      if (mostrar) {
        await this.$store.commit("evento/setEvento", this.evento);
      }
      this.dialogmapasientos = mostrar;
    },

    enviaraCarrito() {

      var lasBoletas = [];
      var losPalcos = [];
      if (this.preciosBoletasCarrito && this.preciosBoletasCarrito.length > 0) {
        for (
          var i = 0;
          i < this.listLocalidadesEventoAuditorio_mapeado.length;
          i++
        ) {
          if (
            this.cantBoleta[
            this.listLocalidadesEventoAuditorio_mapeado[i].id
            ] != null
          ) {
            if (this.listLocalidadesEventoAuditorio_mapeado[i].palco == 1) {
              losPalcos = losPalcos.concat(
                this.cantBoleta[
                this.listLocalidadesEventoAuditorio_mapeado[i].id
                ]
              );
            } else {
              lasBoletas = lasBoletas.concat(
                this.cantBoleta[
                this.listLocalidadesEventoAuditorio_mapeado[i].id
                ]
              );
            }
            this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id]
              .length;
          }
        }
      }

      if (lasBoletas.length > 0 || losPalcos.length > 0) {

        this.$store.state.newboletasclear = lasBoletas;
        this.$store.state.newpalcosclear = losPalcos;
        localStorage.setItem('newboletasclear', JSON.stringify(lasBoletas));
        localStorage.setItem('newpalcosclear', JSON.stringify(losPalcos));
        localStorage.setItem('estadoVuex', JSON.stringify(this.$store.state));
        localStorage.setItem('funcionselected', this.funcionseleccionada);
        // console.log( this.$store.state);
        window.location.href = '/carts/' + this.evento.slug;
        // this.$router.replace('/carts/'+ this.evento.slug);
        //  this.$router.push('/carts/'+ this.evento.slug);
      } else {
        swal("Informacion", "El carrito está vacío.", "info");
        return;
      }

    },
    async verResumenCompra() {

      var cupon = 'NA';
      if (this.currentRoute != "evento" || this.evento.fechaValidaInicioInternet) {
        if (this.$store.getters["user/dataUserLogged"].nombre == null) {
          this.$store.state.modalLogin = true;
        } else {
          if (this.$store.state.carrito.cantBoletaTotal == 0) {
            swal("Carrito Vacio!", "No ha reservado boletas!", "success");
          } else {
            if (this.entregaBoletas != null && this.entregaBoletas != undefined) {
              var lasBoletas = [];
              var losPalcos = [];

              for (var i = 0; i < this.listLocalidadesEventoAuditorio_mapeado.length; i++) {
                if (this.$store.state.carrito.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id] != null) {
                  if (this.listLocalidadesEventoAuditorio_mapeado[i].palco == 1) {
                    losPalcos = losPalcos.concat(
                      this.$store.state.carrito.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id]
                    );
                  } else {
                    lasBoletas = lasBoletas.concat(
                      this.$store.state.carrito.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id]
                    );
                  }
                  this.$store.state.carrito.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id].length;
                }
              }
              if (lasBoletas != [] || losPalcos != []) {
                if (this.$store.state.carrito.cupon != null) {
                  cupon = this.$store.state.carrito.cupon;
                }

                //Eliminar Duplicidad ID
                const duplicidadPalcos = new Set(losPalcos);
                let resultDupliPalcos = [...duplicidadPalcos];

                const duplicidadBoletas = new Set(lasBoletas);
                let resultDupliBoletas = [...duplicidadBoletas];

                await this.$store.dispatch("detalle_Boletas_Finalizarcompra", {
                  palcos: resultDupliPalcos.length > 0 ? resultDupliPalcos : '',
                  boletas: resultDupliBoletas.length > 0 ? resultDupliBoletas : '',
                  cupon: cupon.length > 2 ? cupon : 'NA',
                }).then(() => {
                  if (this.$store.state.errorDetalleCompra) {
                    swal("Parece que algo salio mal!", "Intentalo nuevamente", "error");
                    return '';
                  } else {
                    this.$store.state.showModalFinalizarCompra = true;
                  }
                });




              } else if (lasBoletas == [] && losPalcos == []) {
                swal("Carrito Vacio!", "No ha reservado boletas!", "success");
                return "";
              }
            } else {
              this.modalSelectEntregaBoletas = true;
            }
          }
        }
      } else {
        swal(
          "Error!",
          "Ventas en linea disponible despues de " +
          this.formatDatetimeString(
            this.evento.fecha_inicio_venta_internet_formateada
          ),
          "error"
        );
      }
    },
    async pagar() {
      var cupon = 'NA';


      if (this.evento.id_tipo_evento == 7 && this.funcionseleccionada == 0) {
        swal("Funcion no Selecciona!", "Algo salió mal. Limpiar el carrito e iniciar de nuevo!", "success");
      } else {



        if (
          this.currentRoute != "evento" ||
          this.evento.fechaValidaInicioInternet
        ) {
          if (this.$store.getters["user/dataUserLogged"].nombre == null) {
            this.$store.state.modalLogin = true;
            //swal("Error!", "Debe Iniciar Sesión!", "error");
          } else {
            if (this.$store.state.carrito.cantBoletaTotal == 0) {
              swal("Carrito Vacio!", "No ha reservado boletas!", "success");
            } else {




              if (
                this.entregaBoletas != null &&
                this.entregaBoletas != undefined
              ) {
                var lasBoletas = [];
                var losPalcos = [];

                for (
                  var i = 0;
                  i < this.listLocalidadesEventoAuditorio_mapeado.length;
                  i++
                ) {
                  if (
                    this.$store.state.carrito.cantBoleta[
                    this.listLocalidadesEventoAuditorio_mapeado[i].id
                    ] != null
                  ) {
                    if (
                      this.listLocalidadesEventoAuditorio_mapeado[i].palco == 1
                    ) {
                      losPalcos = losPalcos.concat(
                        this.$store.state.carrito.cantBoleta[
                        this.listLocalidadesEventoAuditorio_mapeado[i].id
                        ]
                      );
                    } else {
                      lasBoletas = lasBoletas.concat(
                        this.$store.state.carrito.cantBoleta[
                        this.listLocalidadesEventoAuditorio_mapeado[i].id
                        ]
                      );
                    }
                    this.$store.state.carrito.cantBoleta[
                      this.listLocalidadesEventoAuditorio_mapeado[i].id
                    ].length;
                  }
                }
                if (lasBoletas != [] && losPalcos == []) {
                  if (this.$store.state.carrito.cupon != null) {
                    cupon = this.$store.state.carrito.cupon;
                  }
                  await this.$store.dispatch("obtener_refventa", {
                    boletas: lasBoletas,
                    moneda: this.$store.getters["evento/monedaSelectShop"],
                    email: this.$store.getters["user/dataUserLogged"].email,
                    nombre: this.$store.getters["user/dataUserLogged"].nombre,
                    identificacion:
                      this.$store.getters["user/dataUserLogged"].identificacion,
                    tipo_identificacion:
                      this.$store.getters["user/dataUserLogged"]
                        .tipo_identificacion,
                    entrega_boletas: this.entregaBoletas,
                    cupon: cupon.length > 2 ? cupon : 'NA',
                    funcionseleccionada: this.funcionseleccionada
                  });
                  this.$store.state.carrito.descuentocupon = 0;
                  this.$store.state.carrito.cuponestado = 0;
                  this.$store.state.carrito.vservicio = 0;
                  this.$store.state.carrito.cupon = '';
                  this.$store.state.carrito.cuponok = false

                } else if (lasBoletas == [] && losPalcos != []) {
                  if (this.$store.state.carrito.cupon != null) {
                    cupon = this.$store.state.carrito.cupon;
                  }
                  await this.$store.dispatch("obtener_refventa", {
                    palcos: losPalcos,
                    moneda: this.$store.getters["evento/monedaSelectShop"],
                    email: this.$store.getters["user/dataUserLogged"].email,
                    nombre: this.$store.getters["user/dataUserLogged"].nombre,
                    identificacion:
                      this.$store.getters["user/dataUserLogged"].identificacion,
                    tipo_identificacion:
                      this.$store.getters["user/dataUserLogged"]
                        .tipo_identificacion,
                    entrega_boletas: this.entregaBoletas,
                    cupon: cupon.length > 2 ? cupon : 'NA',
                    funcionseleccionada: this.funcionseleccionada
                  });
                  this.$store.state.carrito.descuentocupon = 0;
                  this.$store.state.carrito.cuponestado = 0;
                  this.$store.state.carrito.vservicio = 0;
                  this.$store.state.carrito.cupon = '';
                  this.$store.state.carrito.cuponok = false
                } else if (lasBoletas != [] && losPalcos != []) {
                  if (this.$store.state.carrito.cupon != null) {
                    cupon = this.$store.state.carrito.cupon;
                  }
                  await this.$store.dispatch("obtener_refventa", {
                    boletas: lasBoletas,
                    palcos: losPalcos,
                    moneda: this.$store.getters["evento/monedaSelectShop"],
                    email: this.$store.getters["user/dataUserLogged"].email,
                    nombre: this.$store.getters["user/dataUserLogged"].nombre,
                    identificacion:
                      this.$store.getters["user/dataUserLogged"].identificacion,
                    tipo_identificacion:
                      this.$store.getters["user/dataUserLogged"]
                        .tipo_identificacion,
                    entrega_boletas: this.entregaBoletas,
                    cupon: cupon.length > 2 ? cupon : 'NA',
                    funcionseleccionada: this.funcionseleccionada
                  });
                  this.$store.state.carrito.descuentocupon = 0;
                  this.$store.state.carrito.cuponestado = 0;
                  this.$store.state.carrito.vservicio = 0;
                  this.$store.state.carrito.cupon = '';
                  this.$store.state.carrito.cuponok = false
                  this.$store.state.funcioneshora = [];
                } else if (lasBoletas == [] && losPalcos == []) {
                  swal("Carrito Vacio!", "No ha reservado boletas!", "success");
                  return "";
                }

                if (this.$store.getters["get_init_point"] != null) {
                  this.idLocalidad = "";
                  await this.$store.dispatch("evento/clearCantBoleta");
                  await this.$store.dispatch(
                    "carrito/setNullPreciosBoletasCarrito"
                  );
                  await this.$store.dispatch("carrito/dateTimeSaleStart", null);
                  await this.$store.dispatch("carrito/dateTimeSaleEnd", null);
                  window.location.href = this.$store.getters["get_init_point"];
                }
              } else {
                this.modalSelectEntregaBoletas = true;
              }

              //var refventa = this.$store.getters['obtener_refventa'].refVenta;

              //var total = this.$store.getters['obtener_refventa'].precio_total;

              //var moneda = this.listLocalidadesEventoAuditorio_mapeado[0].localidad_evento[0].codigo_moneda;
              //var moneda = this.$store.getters['evento/monedaSelectShop'];

              //await this.$store.dispatch('generarsha',{
              //  moneda: moneda,
              //  refventa: refventa
              //});

              /* var usuario = this.$store.getters['user/dataUserLogged'];
               var info_pago = this.$store.getters['sha'];
  
               this.elhtml2 = '<input type="image" src="https://paymentsbakery.co/gateway/images/payments-bakery.png" border="0" alt="payments-bakery">\
               <input name="usuarioId" type="hidden" value="'+info_pago.usuarioId+'">\
               <input name="firma" type="hidden" value="'+info_pago.firma_codificada+'">\
               <input name="descripcion" type="hidden" value="'+'descripcion'+'">\
               <!-- Descripcon de la Venta Productos-->\
               <input name="refVenta" type="hidden" value="'+info_pago.refVenta+'">\
               <!--  Referencia de Venta Numero de 6 digitos -->\
               <input name="valor" type="hidden" value="'+total+'">\
               <!--  Valor de la Compra -->\
               <input name="moneda" type="hidden" value="'+moneda+'">\
               <!--  Moneda de la Compra:  PERU:  604 soles y 840 dolares. COLOMBIA COP -->\
               <input name="lang" type="hidden" value="SP">\
               <!--  lenguage   SP - Español   EN - Ingles-->\
               <input name="extra1" type="hidden" value="'+'datosCompra'+'">\
               <input name="extra2" type="hidden" value="'+'datosCompra'+'">\
               <input name="extra3" type="hidden" value="'+'datosCompra'+'">\
               <input name="url_respuesta" type="hidden" value="'+info_pago.URLRespuesta+'">\
               <input name="url_confirmacion" type="hidden" value="'+info_pago.URLConfirma+'">\
               <input name="emailComprador" type="hidden" value="'+usuario.email+'"/>\
               <input name="Comprador" type="hidden" value="'+usuario.nombre+'"  />\
               <!--  Nombre Comprador -->\
               <input name="documentoIdentificacion" type="hidden" value="'+'10628701'+'"  />\
               <!--  Documento de Identidad Comprador -->\
               <input name="Test" type="hidden" value="1"/>';
               */
            }
          }
        } else {
          swal(
            "Error!",
            "Ventas en linea disponible despues de " +
            this.formatDatetimeString(
              this.evento.fecha_inicio_venta_internet_formateada
            ),
            "error"
          );
        }
      }
    },
    getPreventa(index) {
      switch (index) {
        case -1:
          this.accion = "Aprobar";
          break;
        case 1:
          this.accion = "Aprobar";
          break;
        case 2:
          this.accion = "Rechazar";
          break;
        case 4:
          this.accion = "Aprobar Con Modificaciones";
          break;
      }
    },
    getLocalidadDescuento(localidad, preventa) {
      var total =
        parseFloat(
          eval(
            "localidad.localidad_evento[0].precio_venta" +
            this.indexMonedaSelectShopEvento
          )
        ) +
        parseFloat(
          eval(
            "localidad.localidad_evento[0].precio_servicio" +
            this.indexMonedaSelectShopEvento
          )
        );

      if (preventa) {
        if (
          preventa.id_localidad == localidad.id ||
          preventa.id_tribuna == localidad.id_tribuna ||
          preventa.id_evento == this.evento.id
        ) {
          if (preventa.precios_monedas) {
            var precioMonedaActual = preventa.precios_monedas.filter(
              (item) => item.codigo_moneda === this.monedaSelectShopEvento
            )[0];

            if (precioMonedaActual) {
              if (precioMonedaActual.descuento_fijo_servicio) {
                total =
                  parseFloat(total) -
                  parseFloat(precioMonedaActual.descuento_fijo_servicio);
              } else if (preventa.porcentaje_descuento_servicio) {
                total =
                  parseFloat(total) -
                  (parseFloat(
                    eval(
                      "localidad.localidad_evento[0].precio_servicio" +
                      this.indexMonedaSelectShopEvento
                    )
                  ) *
                    parseFloat(preventa.porcentaje_descuento_servicio)) /
                  100;
              }
              if (precioMonedaActual.descuento_fijo_precio) {
                total =
                  parseFloat(total) -
                  parseFloat(precioMonedaActual.descuento_fijo_precio);
              } else if (preventa.porcentaje_descuento_precio) {
                total =
                  parseFloat(total) -
                  (parseFloat(
                    eval(
                      "localidad.localidad_evento[0].precio_venta" +
                      this.indexMonedaSelectShopEvento
                    )
                  ) *
                    parseFloat(preventa.porcentaje_descuento_precio)) /
                  100;
              }
            }
          }
        }
      }
      return (total + (total * localidad.localidad_evento[0].impuesto) / 100) <= 0 ? 0 : this.formatNumber(total + (total * localidad.localidad_evento[0].impuesto) / 100);
    },
    getPrecioServicioLocalidad(localidad) {
      var precio_servicio = parseFloat(
        eval(
          "localidad.localidad_evento[0].precio_servicio" +
          this.indexMonedaSelectShopEvento
        )
      );

      return this.formatNumber(precio_servicio);
    },
    getPrecioVentaLocalidad(localidad) {
      var precio_servicio = parseFloat(
        eval(
          localidad.localidad_evento[0].precio_venta +
          this.indexMonedaSelectShopEvento
        )
      );
      return this.formatNumber(precio_servicio);
    },
    changeMonedaShopEvento() {
      this.$store.dispatch("evento/changeMonedaShopEvento", {
        codigo_moneda: this.monedaSelectShop,
        evento_id: this.evento.id,
      });
      this.$router.go(this.$router.currentRoute);
    },
    concatTextMoneda(item) {
      return `(${item.moneda.simbolo}) ${item.moneda.descripcion}`;
    },
    validateDateTimeSale() {
      let fecha = new Date();
      if (this.dateTimeSaleStart != null && this.dateTimeSaleEnd != null) {

        if (this.getBrowser.includes('Safari')) {
          swal("Safari Mode", "Modo Safari", "info")
        }

        if (
          Date.parse(fecha) >= Date.parse(this.dateTimeSaleStart) &&
          Date.parse(fecha) <= Date.parse(this.dateTimeSaleEnd)
        ) {
        } else {
          if (this.dateTimeSaleStart) {
            localStorage.removeItem('mantenerParticipantes');
            localStorage.removeItem('newboletasclear');
            localStorage.removeItem('boletasReservadasPV');
            this.$store.state.showModalFinalizarCompra = false;
            this.$store.state.resumenFinalizarCompra = null;
            this.idLocalidad = "";
            this.$store.dispatch("carrito/dateTimeSaleStart", null);
            this.$store.dispatch("carrito/dateTimeSaleEnd", null);
            this.$store.dispatch("carrito/setNullPreciosBoletasCarrito");
            this.$store.dispatch("evento/clearCantBoleta");
            localStorage.removeItem('estadoVuex');
            swal("Informacion", "El carrito esta vacio.", "info");
          }
        }
      }
    },
    getBrowser() {
      var ua = navigator.userAgent, tem,
        M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (tem[1] || '');
      }
      if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
      if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
      return M.join(' ');
    },
    formatFechaClasic(fecha) {
      if (fecha != null && fecha != "") {
        return moment(fecha).format("YYYY-MM-DD");
      } else {
        return "";
      }
    },

  },
  computed: {
    flatpickrConfig() {

      return {
        inline: true,
        locale: 'es',
        enable: this.$store.state.fechafunciones,
        onChange: this.miFuncion
      };
    },
    pagos() {
      if (this.evento && this.evento.status == 1) {
        return true;
      } else {
        return false;
      }
    },
    hoy() {
      return moment().format("Y-MM-DD");
    },
    cantBoleta() {
      return this.$store.getters["evento/cantBoleta"];
    },
    cantBoletaTotal() {
      return this.$store.getters["evento/cantBoletaTotal"];
    },
    ciudades() {
      return this.$store.getters["ciudad/listCiudades"];
    },
    evento() {
      return this.$store.getters["evento/evento"];
    },
    condicionesEvento() {
      return this.$store.getters["evento/condicionesEvento"];
    },
    preventasEvento() {
      return this.$store.getters["evento/listPreventasEvento"];
    },
    showEventosDestacados() {
      return this.$store.getters["evento/listEventosDestacados"];
    },
    eventosDestacados() {
      return this.showEventosDestacados.slice(0, 4);
    },
    imagenesEvento() {
      return this.$store.getters["evento/imagenesEvento"];
    },
    userAuth() {
      return this.$store.getters["user/dataUserLogged"];
    },
    puntoventasEvento() {
      return this.$store.getters["evento/puntoventasEvento"];
    },
    // listLocalidadesAuditorio_mapeado() {
    //   return this.$store.getters['auditorio_mapeado/listLocalidadesAuditorio_mapeado'];
    // },
    listLocalidadesEventoAuditorio_mapeado() {
      return this.$store.getters[
        "auditorio_mapeado/listLocalidadesEventoAuditorio_mapeado"
      ];
    },
    mapaauditorio() {
      if (
        this.evento.auditorio.latitud != "" &&
        this.evento.auditorio.longitud != ""
      ) {
        return (
          "https://maps.google.com/?ll=" +
          this.evento.auditorio.latitud +
          "," +
          this.evento.auditorio.longitud +
          "&z=14&t=m&output=embed"
        );
      } else {
        return "";
      }
    },
    auditorios() {
      return this.$store.getters["auditorio/listAuditorios"];
    },
    // boletas(){
    //   return this.$store.getters['carrito/listBoletas'];
    // },
    imagenesEventoCarousel() {
      let imagenesEventoCarouselShow = [];
      if (this.imagenesEvento) {
        imagenesEventoCarouselShow = this.imagenesEvento.filter((imagen) => {
          return imagen.imagen.nombre.includes("banner");
        });
      }
      return imagenesEventoCarouselShow;
    },
    monedaSelectShopEvento() {
      return this.$store.getters["evento/monedaSelectShop"];
    },
    indexMonedaSelectShopEvento() {
      if (this.evento.monedas_evento && this.evento.monedas_evento.length > 0) {
        var index = this.evento.monedas_evento.findIndex(
          (moneda) => moneda.codigo_moneda === this.monedaSelectShopEvento
        );
        if (index === 0) {
          return "";
        } else {
          return index + 1;
        }
      }
    },
    simboloMoneda() {
      if (
        this.indexMonedaSelectShopEvento === "" ||
        this.indexMonedaSelectShopEvento === 0
      ) {
        return this.evento ? this.evento.monedas_evento[0].moneda.simbolo : ''
      } else {
        return this.evento ? this.evento.monedas_evento[this.indexMonedaSelectShopEvento - 1] : ''
          .moneda.simbolo;
      }
    },
    preciosBoletasCarrito() {
      return this.$store.getters["carrito/preciosBoletasCarrito"];
    },
    totalValorBoletasCarrito() {
      var preciosBoletasCarritoMonedaActual = this.$store.getters[
        "carrito/preciosBoletasCarrito"
      ].filter((item) => item.codigo_moneda == this.monedaSelectShopEvento);
      if (
        preciosBoletasCarritoMonedaActual &&
        preciosBoletasCarritoMonedaActual.length > 0
      ) {
        var total = 0;
        for (
          var i = preciosBoletasCarritoMonedaActual.length - 1;
          i >= 0;
          i--
        ) {
          total += preciosBoletasCarritoMonedaActual[i].precio;
        }
        return this.formatNumber(total);
      } else {
        return 0;
      }
    },
    localidadSelectCarrito() {
      return this.$store.getters["carrito/localidadSelectCarrito"];
    },
    countBoletas() {
      return this.$store.getters["carrito/countBoletas"];
      /* var lasBoletas = [];
      var losPalcos = [];

      for (var i = 0; i < this.listLocalidadesEventoAuditorio_mapeado.length; i++) {
        if (this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id] != null) {
          if (this.listLocalidadesEventoAuditorio_mapeado[i].palco == 1) {
            losPalcos = losPalcos.concat(this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id]);
          } else {
            lasBoletas = lasBoletas.concat(this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id]);
          }
          this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id].length;
        }
      }

      return lasBoletas.length; */
    },
    countPalcos() {
      return this.$store.getters["carrito/countPalcos"];
      /* var lasBoletas = [];
      var losPalcos = [];

      for (var i = 0; i < this.listLocalidadesEventoAuditorio_mapeado.length; i++) {
        if (this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id] != null) {
          if (this.listLocalidadesEventoAuditorio_mapeado[i].palco == 1) {
            losPalcos = losPalcos.concat(this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id]);
          } else {
            lasBoletas = lasBoletas.concat(this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id]);
          }
          this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id].length;
        }
      }

      return losPalcos.length;*/
    },
    dateTimeSaleStart() {
      return this.$store.getters["carrito/dateTimeSaleStart"];
    },
    dateTimeSaleEnd() {
      return this.$store.getters["carrito/dateTimeSaleEnd"];
    },
    listLocalidadesEventoAuditorioGeneral_mapeado() {
      const listadoVentaNormal =
        this.$store.getters[
        "auditorio_mapeado/listLocalidadesEventoAuditorioGeneral_mapeado"
        ];
      const listadoPreventas =
        this.$store.getters["evento/listPreventasEvento"];
      let listadoFinalPreventas = [];

      for (let i = 0; i < listadoVentaNormal.length; i++) {
        for (let j = 0; j < listadoPreventas.length; j++) {
          if (
            (listadoVentaNormal[i].id_tribuna ==
              listadoPreventas[j].id_tribuna &&
              !listadoFinalPreventas.includes(listadoVentaNormal)) ||
            (listadoVentaNormal[i].id_evento ==
              listadoPreventas[j].id_evento &&
              !listadoFinalPreventas.includes(listadoVentaNormal)) ||
            (listadoVentaNormal[i].id_tribuna ==
              listadoPreventas[j].id_tribuna &&
              !listadoFinalPreventas.includes(listadoVentaNormal))
          ) {
            var tempData = listadoVentaNormal[i];
            tempData.fecha_inicio = listadoPreventas[j].fecha_inicio;
            tempData.fecha_fin = listadoPreventas[j].fecha_fin;
            tempData.total = this.getLocalidadDescuento(
              tempData,
              listadoPreventas[j]
            );
            if (listadoPreventas[j].porcentaje_descuento_precio) {
              tempData.localidad_evento[0].precio_venta =
                (tempData.localidad_evento[0].precio_venta * 100) /
                listadoPreventas[j].porcentaje_descuento_precio;
            } else if (listadoPreventas[j].porcentaje_descuento_servicio) {
              tempData.localidad_evento[0].precio_venta =
                (tempData.localidad_evento[0].precio_venta * 100) /
                listadoPreventas[j].porcentaje_descuento_servicio;
            } else if (
              listadoPreventas[j].precios_monedas[0].descuento_fijo_precio
            ) {
              tempData.localidad_evento[0].precio_venta =
                tempData.localidad_evento[0].precio_venta -
                listadoPreventas[j].precios_monedas[0].descuento_fijo_precio;
            } else if (
              listadoPreventas[j].precios_monedas[0].descuento_fijo_servicio
            ) {
              tempData.localidad_evento[0].precio_venta =
                tempData.localidad_evento[0].precio_venta -
                listadoPreventas[j].precios_monedas[0].descuento_fijo_servicio;
            }
            listadoFinalPreventas.push(tempData);
          }
        }
      }
      return listadoFinalPreventas;
    },
  },
};
</script>
<style>
h6,
li,
p {
  font-size: 0.875rem;
}

.cont-desc-evento {
  background-color: white;
  text-align: center;
  padding: 15px;
  box-shadow: 0px 0px 10px #eaeaea;
  border-radius: 4px;
  border-top: 5px #BC244A solid;
  height: 100%;
}

.cont-mapa-asientos {
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  box-shadow: 0px 0px 10px #dedede;
  border-radius: 5px;
  padding: 10px;
  min-height: 700px;
  background-size: contain;
}

@media (max-width: 1480px) {
  .cont-mapa-asientos {
    min-height: 600px;
  }
}

@media (max-width: 768px) {
  .cont-mapa-asientos {
    min-height: 400px;
  }

  .container-tab {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
}

.cont-mapa-asientos .sub {
  padding: 0px;
}

.cont-preventa-evento {
  background-color: white;
  box-shadow: 0px 0px 10px #dedede;
  border-radius: 5px;
}

.heading-cont-preventa-evento {
  background-color: #1976d2;
  color: white;
  padding: 10px;
  text-align: center;
  margin-bottom: 0px;
}

.sub-cont-preventa-evento {
  border: 1px #c5c5c5 solid;
}

.cont-descuento-evento {
  position: absolute;
  text-align: center;
  top: 60px;
  background-color: red;
  color: white;
  padding: 10px;
  right: 1rem;
  z-index: 2;
  border-radius: 5px;
  box-shadow: 2px 2px 3px 2px #a0a0a0;
}

.container-tab {
  padding-left: 30px;
  padding-right: 30px;
}

.cont-terminos-condiciones {
  border: 1px #bfbfbf solid;
  padding: 10px;
  max-height: 235px;
  overflow-y: scroll;
  background-color: white;
}

.cont-evento {
  background-color: white;
  border: 1px gray solid;
  text-align: right;
  aspect-ratio: 5/6;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 20px;
}

.cont-fecha {
  /*padding:10px;*/
}

.cont-fecha-activo {
  background-color: green;
  position: relative;
  right: -12px;
  padding: 4px 9px;
  color: white;
  border-radius: 3px;
  box-shadow: -1px 3px 4px 0px #000000;
}

.cont-fecha-aplazado {
  background-color: #ffff00;
  position: relative;
  right: -12px;
  padding: 4px 9px;
  color: #000000;
  border-radius: 3px;
  box-shadow: -1px 3px 4px 0px #000000;
}

.cont-fecha-cancelado {
  background-color: red;
  position: relative;
  right: -12px;
  padding: 4px 9px;
  color: #000000;
  border-radius: 3px;
  box-shadow: -1px 3px 4px 0px #000000;
}

.list-condiciones-evento li {
  text-transform: capitalize;
}

.carousel {
  height: auto !important;
}

.mt-50 {
  margin-top: 50px;
}

.color-slide-table {
  color: black !important;
  margin-left: auto;
  margin-right: auto;
}

.container-evento {
  padding-bottom: 4rem;
}

.btn-finalizar-compra {
  background-color: #1867c0 !important;
  color: white !important;
}

.skeleton-banner-principal-evento {
  height: 100%;
  aspect-ratio: 880/448;
}

.img-skeleton .v-skeleton-loader__image {
  height: 500px;
}

.skeleton-banner-principal-evento .v-skeleton-loader .v-skeleton-loader__image,
.skeleton-banner-principal-evento .v-skeleton-loader {
  height: 100%;
}

.tag-img-evento {
  position: absolute;
  display: block;
  right: 0;
  top: .4rem;
  width: 170px;
  z-index: 1;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.container-modal-resumen {
  background-color: white;
  padding: 1.5rem 1rem;
}

@media (max-width: 768px) {
  .container-modal-resumen {
    padding: 1rem .5rem;
  }
}


.custom-calendar .flatpickr-months {
  background-color: #f0f0f0;
  border-bottom: 1px solid #ddd;
  width: 600px;
  height: 40px;
}

.custom-calendar .flatpickr-days .flatpickr-day {
  background-color: #fff;
  color: #333;
}

.custom-calendar .flatpickr-days .flatpickr-day:hover {
  background-color: #eee;
}

.custom-calendar .flatpickr-weekdays .flatpickr-weekday {
  color: #999;
}

.custom-calendar .flatpickr-calendar.open {
  border-color: #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.flatpickr-input {
  display: none;
}

.disabled-date {
  background-color: #696565;
  color: #111010;
}

.custom-flatpickr {}
</style>