<template>
    <v-app>
        <div class="container-payment">
            <div class="row">
                <div class="col-12">
                    <h2 class="my-4 w-100 text-center">Resumen transacción</h2>
                </div>
                <div class="w-100 px-5 d-flex mb-5">
                    <button class="ml-auto btn btn-secondary" @click="$router.push({path: '/'})">Volver al inicio</button>
                </div>
                <div class="col-12 col-lg-12 order-2 order-md-1">
                    <div class="card-resumen">
                        <div class="table-responsive">
                            <table class="tabla_resumen">
                                <tr>
                                    <td><b>Orden</b></td>
                                    <td>{{ datos_compra.orden }}</td>
                                </tr>
                                <tr>
                                    <td><b>Nombre Cliente</b></td>
                                    <td>{{ datos_compra.nombre_cliente }}</td>
                                </tr>
                                <tr>
                                    <td><b>Respuesta banco</b></td>
                                    <td v-if="datos_compra.res_banco == 1">
                                        <v-chip variant="flat" color="green" style="color: white;">
                                            Aprobado
                                        </v-chip>
                                    </td>
                                    <td v-if="datos_compra.res_banco == 2">
                                        <v-chip variant="flat" color="red" style="color: white;">
                                            Rechazado
                                        </v-chip>
                                    </td>
                                    <td v-if="datos_compra.res_banco == 0">
                                        <v-chip variant="flat" color="primary" style="color: white;">
                                            Pendiente Aprobación
                                        </v-chip>
                                    </td>                                
                                </tr>                                
                                <tr>
                                    <td><b>Id de venta</b></td>
                                    <td>{{ datos_compra.id_venta }}</td>
                                </tr>
                                <tr>
                                    <td><b>Fecha transacción</b></td>
                                    <td>{{ datos_compra.fecha_transaccion }}</td>
                                </tr>                                                              
                                <tr>
                                    <td><b>Referencia orden</b></td>
                                    <td>{{ datos_compra.orden }}</td>
                                </tr>
                                <tr>
                                    <td><b>Estado transacción</b></td>
                                    <td>
                                        {{ datos_compra.estado_transaccion }}
                                    </td>
                                </tr>                                
                                <tr>
                                    <td><b>Subtotal compra</b></td>
                                    <td>
                                        {{ '$ '+ formatNumber(datos_compra.subtotal_compra) + ' COP' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Costo Transacción</b></td>
                                    <td>
                                        {{ '$ '+ formatNumber(datos_compra.costo_transaccion) + ' COP' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Total compra</b></td>
                                    <td>{{'$ '+ formatNumber(datos_compra.total_compra) + ' COP' }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-12 col-lg-4 px-3 px-md-4 order-1 order-md-2">
                    <div class="card-resumen mb-3">
                        <h3 class="text-center w-100">Resumen de compra</h3>
                        <div class="divider-resumen"/> -->
                        <!-- <div style="border-bottom: 1px solid #9797973a" class="pb-2 d-flex align-items-center justify-content-between w-100 px-4 px-lg-2" :key="index" v-for="(product, index) in $store.state.responseDataPayment.response_transaction.productos">
                            <div>
                                <p class="mb-0">{{product.descripcion}}</p>
                                <small style="color: gray">Cantidad: {{product.cantidad}}</small> <br>
                                <small style="color: gray">Id: {{product.id}}</small>
                            </div>
                            <p class="mb-0 px-2"><b>{{product.precio}}</b></p>
                        </div> -->
                        <!-- <div class="d-flex justify-content-between ms-auto my-4 pe-3 w-100">
                            <p style="font-size: 18px">TOTAL: </p>
                            <p style="font-size: 18px"><b></b></p>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </v-app>
</template>
<script>

import {configApi} from './../../../api/configApi'
import {headers} from './../../../api/headers'
import axios from 'axios'

export default {
    data() {
        return {
            datos_compra: {
                orden: '',
                res_banco: '',
                id_venta: '',
                fecha_transaccion: '',
                referencia_orden: '',
                estado_transaccion: '',
                subtotal_compra: '',
                total_compra: '',
                costo_transaccion: '',
                nombre_cliente: ''
            }
        }
    },
    created(){
        document.title = "Resumen de pago"
    },
    async mounted() {
    //    console.log(this.$route.params.token)
        await axios.get(configApi.apiUrl+'mp/pagos/resumen/'+this.$route.params.token, headers)
        .then((response) => {            
            this.datos_compra.orden = response.data[0].token_refventa
            this.datos_compra.nombre_cliente = response.data[0].nombre + ' ' + response.data[0].apellidos
            this.datos_compra.res_banco = response.data[0].active
            this.datos_compra.id_venta = response.data[0].id
            this.datos_compra.fecha_transaccion = response.data[0].fecha
            this.datos_compra.referencia_orden =  response.data[0].token_refventa
            this.datos_compra.estado_transaccion = response.data[0].estado_entrega
            this.datos_compra.subtotal_compra = response.data[0].subtotal
            this.datos_compra.costo_transaccion = response.data[0].costo_transaccion
            this.datos_compra.total_compra =  response.data[0].total
          
        }).catch((e) => {
          console.log(e)
        })   

    },
    methods: {        
        formatNumber(number) {
        return new Intl.NumberFormat("es-CO", {
            minimumFractionDigits: 0,
        }).format(number);
        },
    }
}
</script>
<style>
.container-payment{
    width: 100vw;
    height: 100vh;
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
}
.tabla_resumen{
    widows: 100%;
    margin-right: auto;
    margin-left: auto;
}
.tabla_resumen tr td{
    border: 1px solid gray;
    padding: .5rem 1rem;
}
</style>