<template>
    <v-app>
        <br />
        <br />
        <v-container grid-list-md class="col-md-6 col-md-offset-6">
            <div class="text-center col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <img
                src="/images/logo-ticketshop-2.png"
                style="max-width: 150px; width: 150px"
                />
            </div>
            <br />
            <br />
            <div  style="background: rgb(235 234 234);border-width: 1px;border-color: #e68800;border-style:solid;border-radius: 1.5rem;">
                <div class="text-center mb-5 pb-5">
                    <br>
                    <b>Ingrese el código que hemos enviado a su correo para validar el email</b> <br>
                </div>
                <div class="container" id="validarcodigo">
                    <div class="row justify-content-center" >
                        <div class="col-md-2">
                            <div class="form-group vertical-center">
                                <v-text-field
                                    type="text"
                                    ref="field1"
                                    v-model="fieldValues[0]"
                                    v-on:keyup="checkKey(2, $event)"
                                    autocomplete="nope"
                                    required
                                    maxlength="1"
                                    class="form-control text-center no-bottom-line" 
                                    style="height:80px; padding-top:25px; font-size:32px; font-weight:bold; border-bottom:3px solid #666666; background:#FFFFFF;"
                                ></v-text-field>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group vertical-center">
                                <v-text-field
                                    type="text" 
                                    ref="field2"
                                    v-model="fieldValues[1]"
                                    v-on:keyup="checkKey(3, $event)"
                                    autocomplete="nope"
                                    required
                                    maxlength="1"
                                    class="form-control text-center  no-bottom-line" 
                                    style="  height:80px; padding-top:25px;  font-size:32px; font-weight:bold; border-bottom:3px solid #666666; background:#FFFFFF;" 
                                ></v-text-field>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group vertical-center">
                                <v-text-field
                                    type="text"
                                    ref="field3"
                                    v-model="fieldValues[2]"
                                    v-on:keyup="checkKey(4, $event)"
                                    autocomplete="nope"
                                    required
                                    maxlength="1"
                                    class="form-control text-center  no-bottom-line" 
                                    style=" height:80px; padding-top:25px;   font-size:32px; font-weight:bold; border-bottom:3px solid #666666; background:#FFFFFF;" 
                                ></v-text-field>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group vertical-center">
                                <v-text-field
                                    type="text"
                                    ref="field4"
                                    v-model="fieldValues[3]"
                                    v-on:keyup="checkKey(5, $event)"
                                    autocomplete="nope" 
                                    required
                                    maxlength="1"
                                    class="form-control text-center  no-bottom-line" 
                                    style="  height:80px; padding-top:25px;  font-size:32px; font-weight:bold; border-bottom:3px solid #666666; background:#FFFFFF;"
                                    ></v-text-field>
                            </div>
                        </div>
                    </div>   
                </div>
                <div class="text-center  mb-5 pb-5  "  >
                    <v-btn color="warning" class="btn-finalizar-compra"  @click="validarCodigo()">
                        Validar Código 
                    </v-btn>
                </div>
            </div>
        </v-container>
        <br />
        <br />
    </v-app>
</template>
<script>
import axios from 'axios';
import { configApi } from '../api/configApi';
import { headers } from '../api/headers';
import { store } from '../store';

export default {
  async beforeCreate() {

  },
  async created() {
    this.user.email = localStorage.getItem('emailactivacion')
    await this.validarCorreo()
  },
  data() {
    return {
        disabled: false,
        user: {
            codigoverificacion:"",
            codigoenviado:"", 
            email:""
        },
        fieldValues: ['', '', '', ''],
        };
    },
    methods: {
        checkKey(opcion,event) {
            const valueToPreserve = event.key; 
            const keyCode = event.keyCode || event.which;
                event.preventDefault(); 
            if ( (keyCode >= 96 && keyCode <= 105) || (keyCode >= 48 && keyCode <= 57)  ){ 
                this.fieldValues[opcion-1] = valueToPreserve; 
                    if(opcion<5){
                    this.$refs['field' + opcion].focus(); 
                    } 
            }else{
                this.fieldValues[opcion-1] = ''; 
            }
        },
        async validarCodigo(event){
            const codigo =this.fieldValues[0]+ this.fieldValues[1]+ this.fieldValues[2]+ this.fieldValues[3];
            if( codigo.length>1){
                    if( this.user.codigoenviado==codigo){
                        this.fieldValues = []
                        await axios.post(`${configApi.apiUrl}activarusuario`, this.user).then(response => {
                            swal({
                                title: "Su usuario ha sido activado",
                                text: "Puede iniciar sesión o Recuperar su contraseña",
                                icon: "success",
                                buttons: ['Recuperar Contraseña'],
                                dangerMode: false,
                            })
                            .then((willDelete) => {
                                if (willDelete) {
                                    window.location.href = "/passwordReset";
                                }
                            });
                        })
                    }else{
                    swal("Error", "Debes ingresar un código corrrecto", "error");
                    }
            }else{
                swal("Error", "Debes ingresar un código corrrecto ", "error");
            }
        },
        async validarCorreo(event){
            const correo = this.user.email;
            if( correo.length>1){
                await axios.post(`${configApi.apiUrl}enviar_codigo_confirmacion2`, this.user).then((res)=>{
                if(res.status == 200){
                    this.user.codigoenviado=  res.data.success.codigo_confirmacion;
                }else{
                    swal("Correo Electrónico Duplicado", res.data.error, "warning"); 
                }
                });
            }else{
                swal("Campo Vacío", "Debe ingresar un correo válido", "warning");  
            }
        },
    },
    computed: {

    },
};
</script>
<style>
#validarcodigo input {
  text-align: center;     
}

.no-bottom-line .v-input__slot:before,
.no-bottom-line .v-input__slot:after {
  display: none !important; 
}

.no-bottom-line .v-text-field__slot input {
  border-bottom: none !important;
  box-shadow: none !important;  
}
</style>